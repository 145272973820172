import ApresentacaoSection from './apresentacao-section';
import AtalhosSection from './atalhos-section';
import PesquisaPorClienteSection from './pesquisa-por-cliente-section';

const HomePage: React.FC = () => {
  return (
    <>
      <ApresentacaoSection />
      <PesquisaPorClienteSection />
      <AtalhosSection />
    </>
  );
};

export default HomePage;
