import { Navigate, Route, Routes } from 'react-router-dom';
import AppLayout from '../layout/app-layout';
import AuthPage from './auth/auth';
import CalendariosCortesRoutes from './calendario-corte/calendarios-cortes.routes';
import CategoriasBeneficiosRoutes from './categorias-beneficio/categorias-beneficios.routes';
import ClientesPreAprovadosProdutoRoutes from './cliente-pre-aprovado-produto/cliente-pre-aprovado-produto.routes';
import ClientesRoutes from './clientes/clientes.routes';
import ContasCartaoRoutes from './contas-cartao/contas-cartao.routes';
import ContratosTermosUsoRoutes from './contratos-termos-uso/contratos-termos-uso.routes';
import EmbossingCartaoRoutes from './embossing-cartao/embossing-cartao.routes';
import EmissorRoutes from './emissor/emissor.routes';
import FaixasMccRoutes from './faixas-mcc/faixas-mcc.routes';
import FeriadosRoutes from './feriados/feriados.routes';
import GrupoCredenciaisClienteRoutes from './grupo-credenciais-cliente/grupo-credenciais-cliente.routes';
import HistoricoAtividadeUsuariosRoutes from './historicos-atividade-usuarios/historico-atividade-usuario.routes';
import HistoricosAtividadesExternasRoutes from './historicos-atividades-externas/historico-atividade-externa.routes';
import HomePage from './home/home-page';
import ItensDoacaoRoutes from './item-doacao/itens-doacao.routes';
import LancamentosContabeisRoutes from './lancamentos-contabeis/lancamentos-contabeis.routes';
import NotFoundPage from './not-found-page';
import OperacaoRoutes from './operacao/operacao.routes';
import PapeisUsuariosRoutes from './papeis-usuarios/papeis-usuarios.routes';
import ParametrosGeracaoBoletoRoutes from './parametros-geracao-boleto/parametro-geracao-boleto.routes';
import ParametrosGeracaoCartaoRoutes from './parametros-geracao-cartao/parametros-geracao-cartao.routes';
import PedidosAutorizacaoRoutes from './pedidos-autorizacao/pedido-autorizacao.routes';
import PerguntasFrequentesRoutes from './perguntas-frequentes/perguntas-frequentes.routes';
import PerguntasSegurancaRoutes from './perguntas-seguranca/perguntas-seguranca.routes';
import PrazosConfirmacaoAutorizacaoMCCRoutes from './prazos-confirmacao-autorizacao-mcc/prazos-confirmacao-autorizacao-mcc.routes';
import ProdutosRoutes from './produtos/produtos.routes';
import ProtocolosProcessamentoContaRoutes from './protocolo-processamento-conta/protocolo-processamento-conta.routes';
import ProtocolosProcessamentoLoteRoutes from './protocolo-processamento-lote/protocolo-processamento-lote.routes';
import QmrRoutes from './qmr/qmr.routes';
import RelatoriosOperacaoRoutes from './relatorio-operacao/relatorio-operacao.routes';
import RoteirosContabeisRoutes from './roteiros-contabeis/roteiros-contabeis.routes';
import SequenciaisRoutes from './sequenciais/sequenciais.routes';
import SolicitacoesAberturaContaRoutes from './solicitacao-abertura-conta/solicitacao-abertura-conta.routes';
import SolicitacoesClientesRoutes from './solicitacoes-cliente/solicitacoes-clientes.routes';
import TarifasRoutes from './tarifas/tarifas.routes';
import TaxasCambioRoutes from './taxa-cambio/taxas-cambio.routes';
import TaxasMediasSelicRoutes from './taxa-media-selic/taxas-medias-selic.routes';
import TiposBloqueioCartaoRoutes from './tipos-bloqueio-cartao/tipos-bloqueio-cartao.routes';
import TiposBloqueioContaCartaoRoutes from './tipos-bloqueio-conta-cartao/tipos-bloqueio-conta-cartao.routes';
import TiposSolicitacaoClienteRoutes from './tipos-solicitacao-cliente/tipos-solicitacao-cliente.routes';
import TiposTransacaoRoutes from './tipos-transacao/tipos-transacao.routes';
import UsuariosRoutes from './usuarios/usuarios.routes';

const AppRoutes: React.FC = () => {
  return (
    <>
      <Routes>
        <Route path="/login" element={<AuthPage />} />
        <Route element={<AppLayout />}>
          <Route path="/" element={<Navigate to="/home" />} />
          <Route path="home" element={<HomePage />} />
          <Route path="contas-cartao/*" element={<ContasCartaoRoutes />} />
          <Route path="clientes/*" element={<ClientesRoutes />} />
          <Route path="clientes-pre-aprovados-produto/*" element={<ClientesPreAprovadosProdutoRoutes />} />
          <Route path="categorias-beneficio/*" element={<CategoriasBeneficiosRoutes />} />
          <Route path="calendarios-corte/*" element={<CalendariosCortesRoutes />} />
          <Route path="contratos-termos-uso/*" element={<ContratosTermosUsoRoutes />} />
          <Route path="embossing-cartoes/*" element={<EmbossingCartaoRoutes />} />
          <Route path="emissor/*" element={<EmissorRoutes />} />
          <Route path="faixas-mcc/*" element={<FaixasMccRoutes />} />
          <Route path="feriados/*" element={<FeriadosRoutes />} />
          <Route path="grupos-credenciais-cliente/*" element={<GrupoCredenciaisClienteRoutes />} />
          <Route path="historicos-atividades-usuarios/*" element={<HistoricoAtividadeUsuariosRoutes />} />
          <Route path="historicos-atividades-externas/*" element={<HistoricosAtividadesExternasRoutes />} />
          <Route path="itens-doacao/*" element={<ItensDoacaoRoutes />} />
          <Route path="lancamentos-contabeis/*" element={<LancamentosContabeisRoutes />} />
          <Route path="operacao/*" element={<OperacaoRoutes />} />
          <Route path="papeis-usuarios/*" element={<PapeisUsuariosRoutes />} />
          <Route path="parametros-geracao-boleto/*" element={<ParametrosGeracaoBoletoRoutes />} />
          <Route path="parametros-geracao-cartao/*" element={<ParametrosGeracaoCartaoRoutes />} />
          <Route path="pedidos-autorizacao/*" element={<PedidosAutorizacaoRoutes />} />
          <Route path="perguntas-frequentes/*" element={<PerguntasFrequentesRoutes />} />
          <Route path="perguntas-seguranca/*" element={<PerguntasSegurancaRoutes />} />
          <Route path="prazos-confirmacao-autorizacao-mcc/*" element={<PrazosConfirmacaoAutorizacaoMCCRoutes />} />
          <Route path="produtos/*" element={<ProdutosRoutes />} />
          <Route path="protocolos-processamento-conta/*" element={<ProtocolosProcessamentoContaRoutes />} />
          <Route path="protocolos-processamento-lote/*" element={<ProtocolosProcessamentoLoteRoutes />} />
          <Route path="qmr/*" element={<QmrRoutes />} />
          <Route path="relatorios-operacao/*" element={<RelatoriosOperacaoRoutes />} />
          <Route path="roteiros-contabeis/*" element={<RoteirosContabeisRoutes />} />
          <Route path="sequenciais/*" element={<SequenciaisRoutes />} />
          <Route path="solicitacoes-abertura-conta/*" element={<SolicitacoesAberturaContaRoutes />} />
          <Route path="solicitacoes-clientes/*" element={<SolicitacoesClientesRoutes />} />
          <Route path="tarifas/*" element={<TarifasRoutes />} />
          <Route path="taxas-cambio/*" element={<TaxasCambioRoutes />} />
          <Route path="taxas-medias-selic/*" element={<TaxasMediasSelicRoutes />} />
          <Route path="tipos-bloqueio-cartao/*" element={<TiposBloqueioCartaoRoutes />} />
          <Route path="tipos-bloqueio-conta-cartao/*" element={<TiposBloqueioContaCartaoRoutes />} />
          <Route path="tipos-solicitacao-cliente/*" element={<TiposSolicitacaoClienteRoutes />} />
          <Route path="tipos-transacao/*" element={<TiposTransacaoRoutes />} />
          <Route path="usuarios/*" element={<UsuariosRoutes />} />
          <Route path="*" element={<NotFoundPage />} />
        </Route>
      </Routes>
    </>
  );
};

export default AppRoutes;
