import { Route, Routes } from 'react-router-dom';
import DetalhesProcessoAlteracaoDadosUsuarioClientePage from '../processo-alteracao-dados-usuario-cliente/detalhes-processo-alteracao-dados-usuario-cliente-page';
import ContasCartaoPage from '../contas-cartao/contas-cartao-page';
import DetalhesHistoricoClientePage from '../historicos-cliente/detalhes-historico-cliente-page';
import DetalhesClientePage from './detalhes-cliente-page';

const ClientesRoutes: React.FC = () => {
  return (
    <Routes>
      <Route index element={<ContasCartaoPage />} />
      <Route path=":clienteId" element={<DetalhesClientePage />} />
      <Route path=":clienteId/historicos/:historicoId/tipo/:tipoHistorico" element={<DetalhesHistoricoClientePage />} />
      <Route
        path=":clienteId/processo-alteracao-dados-usuario-cliente/:processoAlteracaoDadosUsuarioClienteId"
        element={<DetalhesProcessoAlteracaoDadosUsuarioClientePage />}
      />
    </Routes>
  );
};

export default ClientesRoutes;
