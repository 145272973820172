import classes from './app-footer.module.scss';

export const AppFooter: React.FC = () => {
  return (
    <footer className={`${classes.footer} py-3`}>
      <div className="d-flex justify-content-center align-items-center mb-2">
        <span>
          <strong>tos</strong> - Tamborine Operation System - {new Date().getFullYear()}
        </span>
      </div>

      <div className="d-flex justify-content-center align-items-center">
        <span className="small text-muted">v{process.env.VERSION}</span>
      </div>
    </footer>
  );
};

export default AppFooter;
