import { useCallback, useMemo } from 'react';
import BSBadge from 'react-bootstrap/Badge';
import BSCard from 'react-bootstrap/Card';
import { useNavigate } from 'react-router-dom';

import { AcaoPermissaoPapelUsuarioEnum } from '@tamborineapps/lib-enums';
import RbacPage from '../../components/role-based-access-control/role-based-access-control-page';
import { SmartTable } from '../../components/smart-table/smart-table';
import SmartTableFilters from '../../components/smart-table/smart-table-filters/smart-table-filters';
import {
  SmartTableFilterControlTypesEnum,
  SmartTableFilterProps,
} from '../../components/smart-table/smart-table-filters/table-filter.types';
import { SmartTableColumnProps } from '../../components/smart-table/smart-table-header';
import Title from '../../components/title';
import {
  ApiMultiElementResponse,
  ClassTypesEnum,
  FormatValueEnum,
  formatBRL,
  formatCartao,
  mapearERemoverElementosNulosERepetidos,
  tipoTransacaoLabelMap,
} from '../../helpers';
import { useQuerystring } from '../../hooks/router/use-querystring';
import { useToasts } from '../../hooks/toast/use-toasts';
import { useAppDispatch, useAppSelector } from '../../store/hooks-redux';
import { loadCartoes, selectObjectTodosCartoes } from '../cartoes/cartoes.redux';
import {
  loadPedidosAutorizacao,
  selectPedidosAutorizacaoByFilters,
  selectPedidosAutorizacaoLoadingStateByFilters,
  selectTotalPedidosAutorizacaoByFilters,
} from '../pedidos-autorizacao/pedidos-autorizacao.redux';

const smartFilters = (): SmartTableFilterProps[] => [
  {
    label: 'Data do pedido',
    attribute: 'dataHoraPedido',
    controlType: SmartTableFilterControlTypesEnum.DATE_TIME_RANGE,
  },
  {
    label: 'Autorizado',
    attribute: 'foiAutorizado',
    controlType: SmartTableFilterControlTypesEnum.BOOL_RADIO,
  },
  {
    label: 'Operacao',
    attribute: 'operacao',
    controlType: SmartTableFilterControlTypesEnum.SELECT,
    map: tipoTransacaoLabelMap,
  },
];

const smartColumns = ({ cartoes }: { cartoes: any }): SmartTableColumnProps[] => [
  {
    label: 'Data',
    attribute: 'dataHoraPedido',
    className: 'text-center',
    sortable: true,
    format: FormatValueEnum.DATE_TIME,
  },
  {
    label: 'Motivo',
    attribute: 'motivo',
    className: 'text-center',
  },
  {
    label: 'Estabelecimento',
    attribute: 'mensagem',
    className: 'text-center',
    format: (mensagem: any) => mensagem.nomeLojista,
  },
  {
    label: 'Operação',
    attribute: 'operacao',
    className: 'text-center',
    format: FormatValueEnum.ENUM,
    map: tipoTransacaoLabelMap,
  },
  {
    label: 'Cartão',
    attribute: 'cartao',
    className: 'text-center',
    format: (cartao: any) => (!cartao || !cartoes[cartao] ? '-' : formatCartao(cartoes[cartao]?.numeroTruncado)),
  },
  {
    label: 'Valor',
    attribute: 'mensagem',
    className: 'text-center',
    format: ({ valorFaturamento }: { valorFaturamento: number }) =>
      !valorFaturamento ? '-' : formatBRL(valorFaturamento),
  },
  {
    label: 'Autorizado',
    attribute: 'foiAutorizado',
    className: 'text-center',
    format: (foiAutorizado: boolean) => {
      return (
        <h5 className="m-0">
          <BSBadge pill bg={foiAutorizado ? ClassTypesEnum.SUCCESS : ClassTypesEnum.DANGER}>
            {foiAutorizado ? 'Autorizado' : 'Não autorizado'}
          </BSBadge>
        </h5>
      );
    },
  },
];

const PedidosAutorizacaoPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const useSelector = useAppSelector;

  const { query, maxItemsQuery } = useQuerystring();
  const navigate = useNavigate();
  const { showToast } = useToasts();

  if (!query.sort) {
    query.sort = '-dataHoraPedido';
  }

  const filters = useMemo(() => ({ ...query }), [query]);
  const total = useSelector((state) => selectTotalPedidosAutorizacaoByFilters(state, filters));
  const pedidosAutorizacao = useSelector((state) => selectPedidosAutorizacaoByFilters(state, filters));
  const loadingState = useSelector((state) => selectPedidosAutorizacaoLoadingStateByFilters(state, filters));

  const cartoes = useSelector((state) => selectObjectTodosCartoes(state));

  const loadEntidadesComplementares = useCallback(
    (pedidosAutorizacao: any[]) => {
      const cartoes = mapearERemoverElementosNulosERepetidos(pedidosAutorizacao, 'cartao');

      dispatch(loadCartoes({ query: { _id: { in: cartoes }, ...maxItemsQuery } })).catch((error: Error) => error);
    },
    [dispatch, maxItemsQuery]
  );

  const loadItems = useCallback(
    () =>
      dispatch(loadPedidosAutorizacao({ query }))
        .then(({ payload: { data } }: ApiMultiElementResponse) => {
          const { sort, ...rest } = query;
          if (Object.entries(rest).length > 0 && data.length === 0) {
            return showToast({
              message: 'Nenhum pedido autorização encontrado',
              type: ClassTypesEnum.WARNING,
            });
          }

          return loadEntidadesComplementares(data);
        })
        .catch((error: Error) => error),
    [dispatch, loadEntidadesComplementares, query, showToast]
  );

  return (
    <RbacPage acoesPermissao={AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_PEDIDO_AUTORIZACAO}>
      <div className="d-flex flex-column h-100">
        <Title>Pedidos de autorização</Title>

        <div className="row">
          <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-12 mb-4">
            <SmartTableFilters filters={smartFilters()} />
          </div>

          <div className="col-xxl-10 col-xl-9 col-lg-8 col-md-12 mb-4">
            <BSCard className="p-4">
              <SmartTable
                emptyMessage="Pesquise por pedidos de autorização"
                errorMessage="Erro na listagem de pedidos de autorização"
                usePagination={true}
                loadItems={loadItems}
                columns={smartColumns({ cartoes })}
                items={pedidosAutorizacao}
                loadingState={loadingState}
                size={total}
                onRowClick={(doc) => navigate(doc._id)}
              />
            </BSCard>
          </div>
        </div>
      </div>
    </RbacPage>
  );
};

export default PedidosAutorizacaoPage;
