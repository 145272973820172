import { reducerFactory, actionFactory, selectorFactory } from '../../store';

const context = 'gruposCredenciaisCliente';

const { createOne, loadMany, loadOne, updateOne } = actionFactory(context, {
  buildUrlLoadOne: ({ grupoCredenciaisClienteId }) => `/api/grupo-credenciais-cliente/${grupoCredenciaisClienteId}`,
  buildUrlCreateOne: () => '/api/grupo-credenciais-cliente',
  buildUrlLoadMany: () => '/api/grupo-credenciais-cliente',
  buildUrlUpdateOne: ({ grupoCredenciaisClienteId }) => `/api/grupo-credenciais-cliente/${grupoCredenciaisClienteId}`,
});

const { selectManyByFilters, selectTotalByFilters, selectLoadingStateByFilters, selectObjectAllIds, selectOneById } =
  selectorFactory({
    context,
  });

export const loadGrupoCredenciaisCliente = loadOne;
export const loadGruposCredenciaisCliente = loadMany;
export const createGrupoCredenciaisCliente = createOne;
export const updateGrupoCredenciaisCliente = updateOne;

export const selectGrupoCredencialClienteById = selectOneById;
export const selectObjectTodosGruposCredenciaisCliente = selectObjectAllIds;
export const selectGruposCredenciaisClienteByFilters = selectManyByFilters;
export const selectTotalGruposCredenciaisClienteByFilters = selectTotalByFilters;
export const selectGruposCredenciaisClienteLoadingStateByFilters = selectLoadingStateByFilters;

export const gruposCredenciaisCliente = reducerFactory({ context });
