import { useCallback, useMemo } from 'react';
import { DialogProps } from '../../hooks/dialog/dialog-context';
import BSForm from 'react-bootstrap/Form';
import { useDialog } from '../../hooks/dialog/use-dialog';
import { useQuerystring } from '../../hooks/router/use-querystring';
import { useToasts } from '../../hooks/toast/use-toasts';
import { useAppDispatch } from '../../store/hooks-redux';
import { useSelector } from 'react-redux';
import { AcaoPermissaoPapelUsuarioEnum } from '@tamborineapps/lib-enums';
import ConfirmationDialog from '../../components/dialog/confirmation-dialog';
import RbacElement from '../../components/role-based-access-control/role-based-access-control-element';
import { SmartTableColumnProps } from '../../components/smart-table/smart-table-header';
import { ClassTypesEnum, formatBool } from '../../helpers';
import { SmartTable } from '../../components/smart-table/smart-table';
import { selectSessionUsuario } from '../session-usuario/session-usuario.redux';
import {
  selectCredenciaisClienteByFilters,
  selectCredenciaisClienteLoadingStateByFilters,
  loadCredenciaisCliente,
  updateCredencialCliente,
  selectTotalCredenciaisClienteByFilters,
} from './credenciais-cliente.redux';

type CredenciaisClientesTableProps = {
  grupoId: string;
};

const smartColumns = ({
  sessionUsuario,
  showDialog,
  closeDialog,
  onUpdateCredencialClienteHandler,
}: {
  sessionUsuario: any;
  showDialog: (dialogProps: DialogProps) => void;
  closeDialog: () => void;
  onUpdateCredencialClienteHandler: (credencialClienteId: string, data: any) => void;
}): SmartTableColumnProps[] => [
  {
    label: 'Nome',
    attribute: 'nome',
    className: 'text-center',
  },
  {
    label: 'Ativo',
    className: 'text-center',
    format: (_, clientCredential) => {
      if (!sessionUsuario.papelUsuario?.permissoes?.ALTERACAO_CLIENT_CREDENTIALS) {
        return <div className="text-center">{formatBool(clientCredential?.ativo)}</div>;
      }

      return (
        <RbacElement acoesPermissao={AcaoPermissaoPapelUsuarioEnum.ALTERACAO_CLIENT_CREDENTIALS}>
          <BSForm.Switch
            checked={clientCredential.ativo}
            name="ativo"
            role="button"
            onChange={() => {
              showDialog({
                component: (
                  <ConfirmationDialog
                    onConfirmation={() =>
                      onUpdateCredencialClienteHandler(clientCredential._id, { ativo: !clientCredential.ativo })
                    }
                    message={`Tem certeza que deseja alterar o status de ativação do usuário: ${clientCredential.nome}?`}
                    confirmationLabel="Alterar"
                    title="Alterar ativação do usuário"
                    onHide={closeDialog}
                  />
                ),
              });
            }}
          />
        </RbacElement>
      );
    },
  },
];
export const CredenciaisClientesTable: React.FC<CredenciaisClientesTableProps> = ({ grupoId }) => {
  const dispatch = useAppDispatch();
  const { query } = useQuerystring();
  const { closeDialog, showDialog } = useDialog();
  const { showToast } = useToasts();
  const [sessionUsuario] = useSelector((state) => selectSessionUsuario(state, {})) ?? [];
  const filters = useMemo(() => ({ ...query }), [query]);
  const clients = useSelector((state) =>
    selectCredenciaisClienteByFilters(state, { grupoCredenciaisClienteId: grupoId })
  );
  const total = useSelector((state) =>
    selectTotalCredenciaisClienteByFilters(state, { grupoCredenciaisClienteId: grupoId })
  );

  const loadingState = useSelector((state) => selectCredenciaisClienteLoadingStateByFilters(state, filters));

  const loadItems = useCallback(
    () =>
      dispatch(loadCredenciaisCliente({ query: { grupoCredenciaisClienteId: grupoId } })).catch(
        (error: Error) => error
      ),
    [dispatch, grupoId]
  );

  const onUpdateCredencialClienteHandler = (credencialClienteId: string, data: any) => {
    if (!data) {
      return;
    }

    dispatch(updateCredencialCliente({ params: { credencialClienteId }, data }))
      .then(() => {
        closeDialog();

        showToast({
          message: 'Ativação do client credential atualizada com sucesso',
          type: ClassTypesEnum.SUCCESS,
        });

        return loadItems();
      })
      .catch((error: Error) => {
        showToast({
          message: error.message,
          type: ClassTypesEnum.DANGER,
        });
      });
  };
  return (
    <>
      <SmartTable
        emptyMessage="Nenhum client credential encontrado"
        errorMessage="Erro na listagem de clients credentials"
        usePagination={true}
        loadItems={loadItems}
        columns={smartColumns({
          sessionUsuario,
          closeDialog,
          showDialog,
          onUpdateCredencialClienteHandler,
        })}
        items={clients}
        loadingState={loadingState}
        size={total}
      />
    </>
  );
};
