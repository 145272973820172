import { AcaoPermissaoPapelUsuarioEnum } from '@tamborineapps/lib-enums';
import { useEffect, useMemo, useState } from 'react';
import BSTab from 'react-bootstrap/Tab';
import BSTabs from 'react-bootstrap/Tabs';
import { useParams, useSearchParams } from 'react-router-dom';

import { DetailCard } from '../../components/details/datail-card';
import DetailElement from '../../components/details/detail-element';
import DetailTitle from '../../components/details/detail-title';
import DetailSubTitle from '../../components/details/detail-subtitle';
import { Loading } from '../../components/loading';
import Page from '../../components/page';
import RbacElement from '../../components/role-based-access-control/role-based-access-control-element';
import { FormatValueEnum, formatCpfCnpj, sexoLabelMap, tipoPessoaLabelMap } from '../../helpers';
import { useQuerystring } from '../../hooks/router/use-querystring';
import { useAppDispatch, useAppSelector } from '../../store/hooks-redux';
import AccessDeniedTab from '../access-denied/access-denied-tab';
import {
  loadDadosComunicacaoClienteCliente,
  selectMapAllByAttributeDadosComunicacaoCliente,
} from '../dados-comunicacao-cliente/dados-comunicacao-cliente.redux';
import AplicativoTab from './aplicativo-tab';
import { loadCliente, selectClienteById, selectClientesLoadingStateByFilters } from './clientes.redux';
import ContasCartaoClienteTab from './contas-cartao-cliente-tab';
import EnderecosClienteTab from './enderecos-cliente-tab';
import HistoricoClienteTab from './historico-cliente-tab';

const DetalhesClientePage: React.FC = () => {
  const dispatch = useAppDispatch();
  const useSelector = useAppSelector;
  const params = useParams();
  const clienteId = params.clienteId as string;

  const { tab } = useQuerystring();
  const [, setSearchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState<string>(tab ?? 'contasCartao');

  const onTabClickHandler = (eventKey: any) => {
    setSearchParams({ _tab: eventKey });
    setActiveTab(eventKey);
  };

  const filters = useMemo(() => ({ clienteId }), [clienteId]);
  const cliente = useSelector((state) => selectClienteById(state, clienteId));
  const dadosComunicacaoCliente = useSelector((state) =>
    selectMapAllByAttributeDadosComunicacaoCliente(state, 'clienteId')
  );
  const loadingState = useSelector((state) => selectClientesLoadingStateByFilters(state, filters));

  const [comunicacaoCliente] = dadosComunicacaoCliente.get(clienteId) || [{}];

  useEffect(() => {
    dispatch(loadCliente({ clienteId })).catch((error: Error) => error);
    dispatch(loadDadosComunicacaoClienteCliente({ clienteId })).catch((error: Error) => error);
  }, [clienteId, dispatch]);

  if (!cliente) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <Loading notFoundMessage="Cliente não econtrado" loadingState={loadingState} />
      </div>
    );
  }

  return (
    <Page>
      <div className="mb-5">
        <DetailCard>
          <div className="mb-5">
            <DetailTitle>
              Cliente: {cliente.nome} - {formatCpfCnpj(cliente.cpfCnpj)}
            </DetailTitle>
          </div>

          <div className="row">
            <div className="col-lg-4 col-md-6 mb-4">
              <DetailElement descricao="Código" valor={cliente.codigo} />
              <DetailElement
                descricao="Tipo de cliente"
                valor={cliente.tipo}
                format={FormatValueEnum.ENUM}
                map={tipoPessoaLabelMap}
              />
              <DetailElement descricao="UF do RG" valor={cliente.ufRg} />
            </div>
            <div className="col-lg-4 col-md-6 mb-4">
              <DetailElement
                descricao="CPF/CNPJ"
                valorClipboard={cliente.cpfCnpj}
                valor={cliente.cpfCnpj}
                copiavel
                format={FormatValueEnum.CPF_CNPJ}
              />
              <DetailElement
                descricao="Data de nascimento"
                valor={cliente.dataNascimento}
                format={FormatValueEnum.DATA}
              />
              <DetailElement descricao="Nome da mãe" valor={cliente.nomeMae} />
            </div>
            <div className="col-lg-4 col-md-6 mb-4">
              <DetailElement descricao="RG" valor={cliente.numeroRg} />
              <DetailElement descricao="Sexo" valor={cliente.sexo} format={FormatValueEnum.ENUM} map={sexoLabelMap} />
              <DetailElement
                descricao="Possui deficiência visual"
                valor={cliente.ehDeficienteVisual}
                format={FormatValueEnum.BOOL}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6 mb-4">
              <div className="mb-3">
                <DetailSubTitle>Comunicação</DetailSubTitle>
              </div>
              <DetailElement descricao="Email" valor={comunicacaoCliente.email} copiavel />
              <DetailElement
                descricao="Telefone"
                valor={comunicacaoCliente.telefone}
                format={FormatValueEnum.TELEFONE}
              />
              <DetailElement descricao="Token FCM" valor={comunicacaoCliente?.dispositivoMovel?.tokenFCM} />
              <DetailElement
                descricao="Endpoint de notificação"
                valor={comunicacaoCliente?.dispositivoMovel?.endpointNotificacao}
              />
            </div>
          </div>
        </DetailCard>
      </div>

      <DetailCard>
        <BSTabs activeKey={activeTab} fill unmountOnExit onSelect={onTabClickHandler}>
          <BSTab eventKey="contasCartao" title="Contas">
            <RbacElement
              acoesPermissao={AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_CONTA_CARTAO}
              componentToRender={<AccessDeniedTab />}
            >
              <ContasCartaoClienteTab clienteId={cliente._id} />
            </RbacElement>
          </BSTab>
          <BSTab eventKey="aplicativo" title="Aplicativo">
            <AplicativoTab clienteId={cliente._id} />
          </BSTab>
          <BSTab eventKey="enderecos" title="Endereços">
            <EnderecosClienteTab clienteId={cliente._id} />
          </BSTab>
          <BSTab eventKey="historico" title="Histórico">
            <RbacElement
              acoesPermissao={AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_HISTORICO_CLIENTE}
              componentToRender={<AccessDeniedTab />}
            >
              <HistoricoClienteTab clienteId={cliente._id} />
            </RbacElement>
          </BSTab>
        </BSTabs>
      </DetailCard>
    </Page>
  );
};

export default DetalhesClientePage;
