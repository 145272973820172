import React, { useMemo } from 'react';
import BSBadge from 'react-bootstrap/Badge';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { SmartTable } from '../../components/smart-table/smart-table';
import { SmartTableColumnProps } from '../../components/smart-table/smart-table-header';
import { FormatValueEnum, naturezaTransacaoColorMap, naturezaTransacaoLabelMap } from '../../helpers';
import { useQuerystring } from '../../hooks/router/use-querystring';
import { useAppDispatch } from '../../store/hooks-redux';
import {
  loadTransacoesOriginadas,
  selectTotalTransacoesByFilters,
  selectTransacoesByFilters,
  selectTransacoesLoadingStateByFilters,
} from './transacoes.redux';
const smartColumns = (): SmartTableColumnProps[] => [
  {
    label: 'Data da transação',
    attribute: 'dataHoraTransacao',
    format: FormatValueEnum.DATE_TIME,
    sortable: true,
    className: 'text-center',
  },
  { label: 'Descrição', attribute: 'descricao', className: 'text-center' },
  {
    label: 'Natureza',
    attribute: 'natureza',
    sortable: true,
    className: 'text-center',
    format(natureza: keyof typeof naturezaTransacaoLabelMap) {
      return (
        <h5 className="m-0">
          <BSBadge pill bg={naturezaTransacaoColorMap[natureza as keyof typeof naturezaTransacaoColorMap]}>
            {naturezaTransacaoLabelMap[natureza]}
          </BSBadge>
        </h5>
      );
    },
  },
  {
    label: 'Valor (R$)',
    attribute: 'valor',
    format: FormatValueEnum.BRL,
    className: 'text-center',
    sortable: true,
  },
  { label: 'Parcela Atual', attribute: 'parcelaAtual', className: 'text-center', sortable: true },
  {
    label: 'Quantidade de parcelas',
    attribute: 'quantidadeParcelas',
    className: 'text-center',
  },
];

type TransacoesOriginadasTabProps = {
  transacaoId: string;
};

const TransacoesOriginadasTab: React.FC<TransacoesOriginadasTabProps> = ({ transacaoId }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { query } = useQuerystring();

  if (!query.sort) {
    query.sort = '-dataHoraTransacao';
  }

  const filters = useMemo(() => ({ ...query, transacaoId }), [query, transacaoId]);

  const total = useSelector((state) => selectTotalTransacoesByFilters(state, filters));
  const transacoes = useSelector((state) => selectTransacoesByFilters(state, filters));
  const loadingState = useSelector((state) => selectTransacoesLoadingStateByFilters(state, filters));

  const loadItemsWithoutUseCallback = () =>
    dispatch(loadTransacoesOriginadas({ params: { transacaoId }, query })).catch((error: Error) => error);

  return (
    <SmartTable
      emptyMessage="Nenhuma transação encontrada"
      errorMessage="Erro ao listar transações"
      loadItems={loadItemsWithoutUseCallback}
      usePagination={true}
      size={total}
      columns={smartColumns()}
      items={transacoes}
      loadingState={loadingState}
      onRowClick={(doc) => navigate(`/contas-cartao/${doc.contaCartao}/transacoes/${doc._id}`)}
    />
  );
};

export default TransacoesOriginadasTab;
