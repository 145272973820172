import { AcaoPermissaoPapelUsuarioEnum } from '@tamborineapps/lib-enums';
import { useCallback, useEffect, useMemo, useState } from 'react';
import BSCard from 'react-bootstrap/Card';
import BSCollapse from 'react-bootstrap/Collapse';
import { useParams } from 'react-router-dom';
import { DetailCard } from '../../components/details/datail-card';
import DetailElement from '../../components/details/detail-element';
import DetailSubTitle from '../../components/details/detail-subtitle';
import DetailTitle from '../../components/details/detail-title';
import { Loading } from '../../components/loading';
import RbacPage from '../../components/role-based-access-control/role-based-access-control-page';
import { ApiSingleElementResponse, FormatValueEnum } from '../../helpers';
import { useAppDispatch, useAppSelector } from '../../store/hooks-redux';
import {
  loadCredencialCliente,
  selectCredencialClienteById,
} from '../grupo-credenciais-cliente/credenciais-cliente.redux';
import {
  loadHistoricoAtividadeExterna,
  selectHistoricoAtividadeExternaById,
  selectHistoricoAtividadeExternaLoadingStateByFilters,
} from './historico-atividade-externa.redux';

const DetalhesHistoricoAtividadeExternaPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const useSelector = useAppSelector;

  const params = useParams();
  const historicoAtividadeExternaId = params.historicoAtividadeExternaId as string;

  const filters = useMemo(() => ({ historicoAtividadeExternaId }), [historicoAtividadeExternaId]);
  const historicoAtividadeExterna = useSelector((state) =>
    selectHistoricoAtividadeExternaById(state, historicoAtividadeExternaId)
  );
  const loadingState = useSelector((state) => selectHistoricoAtividadeExternaLoadingStateByFilters(state, filters));
  const client = useSelector((state) => selectCredencialClienteById(state, historicoAtividadeExterna?.client));

  const loadEntidadesComplementares = useCallback(
    (historico: any) => {
      dispatch(loadCredencialCliente({ CredencialClienteId: historico.client })).catch((error: Error) => error);
    },
    [dispatch]
  );

  const _loadHistorico = useCallback(
    () =>
      dispatch(loadHistoricoAtividadeExterna({ historicoAtividadeExternaId }))
        .then(({ payload: { data } }: ApiSingleElementResponse) => loadEntidadesComplementares(data))
        .catch((error: Error) => error),
    [dispatch, historicoAtividadeExternaId, loadEntidadesComplementares]
  );

  useEffect(() => {
    _loadHistorico();
  }, [_loadHistorico]);

  if (!historicoAtividadeExterna) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <Loading notFoundMessage="Registro de histórico não econtrado!" loadingState={loadingState} />;
      </div>
    );
  }

  return (
    <RbacPage acoesPermissao={AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_HISTORICO_ATIVIDADE_EXTERNA}>
      <div className="mb-5">
        <DetailCard>
          <div className="mb-5">
            <DetailTitle>
              Registro de atividade externa{' '}
              {client && (
                <span>
                  - <strong>{client.nome}</strong>
                </span>
              )}
            </DetailTitle>
          </div>

          <div className="row mb-4">
            <div className="col-lg-4 col-md-6">
              <DetailElement
                descricao="Data da requisição"
                valor={historicoAtividadeExterna.dataHoraRequisicao}
                format={FormatValueEnum.DATE_TIME}
              />
              <DetailElement descricao="URL" valor={historicoAtividadeExterna.url} />
              <DetailElement descricao="Método HTTP" valor={historicoAtividadeExterna.metodo} />
              <DetailElement descricao="IP" valor={historicoAtividadeExterna.ip} />
              <DetailElement descricao="Origem da alteração" valor={historicoAtividadeExterna.origemAlteracao} />
              <DetailElement descricao="Código de resposta HTTP" valor={historicoAtividadeExterna.statusCode} />
              {historicoAtividadeExterna.client && <DetailElement descricao="Client" valor={client?.nome} />}
            </div>
          </div>

          <hr className="mb-5" />

          {historicoAtividadeExterna.body && Object.keys(historicoAtividadeExterna.body).length !== 0 && (
            <div className="mb-3">
              <RequestParamCard requestParamProps={historicoAtividadeExterna.body} title="Body" />
            </div>
          )}

          {historicoAtividadeExterna.params && Object.keys(historicoAtividadeExterna.params).length !== 0 && (
            <div className="mb-3">
              <RequestParamCard requestParamProps={historicoAtividadeExterna.params} title="Params" />
            </div>
          )}

          {historicoAtividadeExterna.query && Object.keys(historicoAtividadeExterna.query).length !== 0 && (
            <div className="mb-3">
              <RequestParamCard requestParamProps={historicoAtividadeExterna.query} title="Query" />
            </div>
          )}

          {historicoAtividadeExterna.headers && Object.keys(historicoAtividadeExterna.headers).length !== 0 && (
            <div className="mb-3">
              <RequestParamCard requestParamProps={historicoAtividadeExterna.headers} title="Headers" isHeaders />
            </div>
          )}

          {historicoAtividadeExterna.payload && Object.keys(historicoAtividadeExterna.payload).length !== 0 && (
            <div className="mb-3">
              <RequestParamCard requestParamProps={historicoAtividadeExterna.payload} title="Payload" />
            </div>
          )}
        </DetailCard>
      </div>
    </RbacPage>
  );
};

type RequestParamCardProps = {
  requestParamProps: any;
  title: string;
  isHeaders?: boolean;
};

const RequestParamCard: React.FC<RequestParamCardProps> = ({ requestParamProps, title, isHeaders = false }) => {
  const [cardOpen, setCardOpen] = useState<boolean>(true);

  const formatString = (value: any, isHeaders: Boolean) => {
    if (value && Object.keys(value).length !== 0) {
      const valueStr = JSON.stringify(value);

      if (isHeaders) {
        return valueStr
          .split(/","/)
          .map((el) => el.replace(/[{"]/g, ' ').replace(/["}]/g, ' ').replace(/[":"]/g, ': '));
      }

      return valueStr.split(/,/).map((el) => el.replace(/[{"]/g, ' ').replace(/["}]/g, ' ').replace(/[":"]/g, ': '));
    }

    return [];
  };

  const onClickHandler = () => {
    setCardOpen((prev) => !prev);
  };

  return (
    <BSCard role="button" onClick={onClickHandler}>
      <BSCard.Body>
        <DetailSubTitle>{title}</DetailSubTitle>

        <BSCollapse in={cardOpen}>
          <div>
            <div className="mt-3 ps-3">
              <div className="row">
                {formatString(requestParamProps, isHeaders).map((value, index) => (
                  <div key={index} className="col-md-6">
                    <li>{value}</li>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </BSCollapse>
      </BSCard.Body>
    </BSCard>
  );
};

export default DetalhesHistoricoAtividadeExternaPage;
