import { logoTbr } from '../../assets';
import classes from './auth-branding.module.scss';

const AuthBranding: React.FC = () => {
  return (
    <div className={`${classes.bg} h-100 p-5`}>
      <div className="d-flex justify-content-end justify-content-lg-start">
        <img className={classes.img} src={logoTbr} alt="brand" />
      </div>
    </div>
  );
};

export default AuthBranding;
