import BSButton from 'react-bootstrap/Button';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Form } from '../../components/form/form';
import { FormControl } from '../../components/form/form-control';
import { ClassTypesEnum, SizesEnum } from '../../helpers';
import { useToasts } from '../../hooks/toast/use-toasts';
import { useAppDispatch } from '../../store/hooks-redux';
import { auth } from '../session-usuario/session-usuario.redux';
import { logo } from '../../assets';

import classes from './auth-form.module.scss';

type AuthFormFields = {
  email: string;
  password: string;
};

const AuthForm: React.FC = () => {
  const { showToast } = useToasts();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const form = useForm<AuthFormFields>();
  const { control } = form;

  const onSubmitHandler = (data: AuthFormFields) => {
    dispatch(auth({ data }))
      .then(() => navigate('/'))
      .catch((error: Error) => {
        showToast({ message: error.message, type: ClassTypesEnum.DANGER });
      });
  };

  return (
    <div className="d-flex flex-column justify-content-lg-center align-items-center h-100 py-5 px-3 px-md-5">
      <div className="d-flex justify-content-center align-items-center mb-5">
        <div className="me-3">
          <img src={logo} alt="logo" className={classes.logo} />
        </div>

        <div>
          <span className={`${classes['logo-text']} m-0`}>tamborine</span>
          <h3 className="m-0">Operation System</h3>
        </div>
      </div>

      <h3 className="mb-5">
        <strong>Login</strong>
      </h3>

      <div className="row w-100 justify-content-center">
        <Form form={form} onSubmit={onSubmitHandler} className="col-md-12 col-lg-10 col-xl-8">
          <div className="mb-5">
            <FormControl
              className="mb-3"
              control={control}
              label="E-mail"
              type="email"
              name="email"
              size={SizesEnum.LARGE}
              rules={{ required: true }}
            />

            <FormControl
              className="mb-3"
              control={control}
              label="Senha"
              type="password"
              name="password"
              size={SizesEnum.LARGE}
              rules={{ required: true }}
            />
          </div>

          <BSButton className="w-100" variant="primary" type="submit" size={SizesEnum.LARGE}>
            Acessar
          </BSButton>
        </Form>
      </div>
    </div>
  );
};

export default AuthForm;
