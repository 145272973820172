import { useCallback, useMemo } from 'react';
import BSCard from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';

import { AcaoPermissaoPapelUsuarioEnum } from '@tamborineapps/lib-enums';
import RbacElement from '../../components/role-based-access-control/role-based-access-control-element';
import RbacPage from '../../components/role-based-access-control/role-based-access-control-page';
import ExportCSVButton from '../../components/smart-table/export-csv-button';
import { SmartTable } from '../../components/smart-table/smart-table';
import SmartTableButton from '../../components/smart-table/smart-table-button';
import SmartTableFilters from '../../components/smart-table/smart-table-filters/smart-table-filters';
import {
  SmartTableFilterControlTypesEnum,
  SmartTableFilterProps,
} from '../../components/smart-table/smart-table-filters/table-filter.types';
import { SmartTableColumnProps } from '../../components/smart-table/smart-table-header';
import Title from '../../components/title';
import {
  ApiMultiElementResponse,
  FormatValueEnum,
  formatData,
  mapearERemoverElementosNulosERepetidos,
  mesLabelMap,
} from '../../helpers';
import { useDialog } from '../../hooks/dialog/use-dialog';
import { useQuerystring } from '../../hooks/router/use-querystring';
import { useAppDispatch, useAppSelector } from '../../store/hooks-redux';
import { loadProdutos, selectObjectTodosProdutos } from '../produtos/produtos.redux';
import CadastrarCalendarioCorteDialog from './cadastrar-calendario-corte-dialog';
import {
  loadCalendariosDeCorte,
  selectCalendarioCorteLoadingStateByFilters,
  selectCalendariosCorteByFilters,
  selectTotalCalendariosCorteByFilters,
} from './calendarios-corte.redux';

const smartFilters = (): SmartTableFilterProps[] => [
  {
    label: 'Data de Prévia',
    attribute: 'dataPrevia',
    controlType: SmartTableFilterControlTypesEnum.DATE_INPUT,
    className: 'align-text-center',
  },
  {
    label: 'Data de corte',
    attribute: 'dataCorte',
    controlType: SmartTableFilterControlTypesEnum.DATE_INPUT,
  },
  {
    label: 'Data de vencimento',
    attribute: 'dataVencimento',
    controlType: SmartTableFilterControlTypesEnum.DATE_INPUT,
  },
  {
    label: 'Dia',
    attribute: 'dia',
    controlType: SmartTableFilterControlTypesEnum.TEXT_INPUT,
  },
  { label: 'Mes', attribute: 'mes', controlType: SmartTableFilterControlTypesEnum.SELECT, map: mesLabelMap },
  {
    label: 'Ano',
    attribute: 'ano',
    controlType: SmartTableFilterControlTypesEnum.TEXT_INPUT,
  },
  {
    label: 'Código do produto',
    attribute: 'codigoProduto',
    controlType: SmartTableFilterControlTypesEnum.TEXT_INPUT,
  },
  {
    label: 'Produto',
    attribute: 'nomeProduto',
    controlType: SmartTableFilterControlTypesEnum.TEXT_INPUT,
    minLength: 2,
  },
];
const smartColumns = ({ produtos }: { produtos: any }): SmartTableColumnProps[] => [
  {
    label: 'Data de corte',
    attribute: 'dataCorte',
    format: FormatValueEnum.DATA,
    className: 'text-center',
    sortable: true,
  },
  {
    label: 'Data de vencimento',
    attribute: 'dataVencimento',
    format: FormatValueEnum.DATA,
    className: 'text-center',
    sortable: true,
  },
  {
    label: 'Data de Prévia',
    attribute: 'dataPrevia',
    format: FormatValueEnum.DATA,
    className: 'text-center',
    sortable: true,
  },
  {
    label: 'Dia',
    attribute: 'dia',
    className: 'text-center',
  },
  { label: 'Mes', attribute: 'mes', className: 'text-center' },
  {
    label: 'Ano',
    attribute: 'ano',
    className: 'text-center',
  },
  {
    label: 'Produto',
    attribute: 'produto',
    format(produto) {
      if (!produto) {
        return '-';
      }

      return (
        <Link onClick={(e) => e.stopPropagation()} to={`/produtos/${produto}`}>
          {produtos[produto]?.nome}
        </Link>
      );
    },
    className: 'text-center',
    sortable: true,
  },
];

const csvHeaders = [
  { label: 'Data de corte', key: 'dataCorte' },
  { label: 'Data de vencimento', key: 'dataVencimento' },
  { label: 'Data de Prévia', key: 'dataPrevia' },
  { label: 'Dia', key: 'dia' },
  { label: 'Mes', key: 'mes' },
  { label: 'Ano', key: 'ano' },
  { label: 'Produto', key: 'produto' },
];
const CalendariosCortePage: React.FC = () => {
  const dispatch = useAppDispatch();
  const useSelector = useAppSelector;
  const { query, maxItemsQuery } = useQuerystring();
  const { closeDialog, showDialog } = useDialog();

  if (!query.sort) {
    query.sort = '-dataCorte';
  }

  const filters = useMemo(() => ({ ...query }), [query]);
  const produtos = useSelector((state) => selectObjectTodosProdutos(state));
  const total = useSelector((state) => selectTotalCalendariosCorteByFilters(state, filters));
  const calendariosCorte = useSelector((state) => selectCalendariosCorteByFilters(state, filters));
  const loadingState = useSelector((state) => selectCalendarioCorteLoadingStateByFilters(state, filters));

  const loadEntidadesComplementares = useCallback(
    (calendariosCorte: any[]) => {
      const produtos = mapearERemoverElementosNulosERepetidos(calendariosCorte, 'produto');

      dispatch(loadProdutos({ query: { _id: { in: produtos }, ...maxItemsQuery } })).catch((error: Error) => error);
    },
    [dispatch, maxItemsQuery]
  );

  const loadItems = useCallback(
    () =>
      dispatch(loadCalendariosDeCorte({ query }))
        .then(({ payload: { data } }: ApiMultiElementResponse) => loadEntidadesComplementares(data))
        .catch((error: Error) => error),
    [dispatch, loadEntidadesComplementares, query]
  );

  const onNovoCalendarioCorteClickHandler = () => {
    showDialog({ component: <CadastrarCalendarioCorteDialog closeDialog={closeDialog} reload={loadItems} /> });
  };
  const calendariosCorteExcel = calendariosCorte?.map(({ ...dadosCalendarioCorte }: any) => {
    let novosDados = dadosCalendarioCorte;
    novosDados['dataCorte'] = formatData(dadosCalendarioCorte.dataCorte);
    novosDados['dataVencimento'] = formatData(dadosCalendarioCorte.dataVencimento);
    novosDados['dataPrevia'] = formatData(dadosCalendarioCorte.dataPrevia);
    novosDados['produto'] = produtos?.[novosDados.produto]?.nome;
    return novosDados;
  });

  return (
    <RbacPage acoesPermissao={AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_CALENDARIO_CORTE}>
      <div className="d-flex flex-column h-100">
        <Title> Calendários de corte </Title>

        <div className="row">
          <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-12 mb-4">
            <SmartTableFilters filters={smartFilters()} />
          </div>

          <div className="col-xxl-10 col-xl-9 col-lg-8 col-md-12 mb-4">
            <BSCard>
              <BSCard.Body>
                <div className="d-flex align-items-center mb-2">
                  <RbacElement acoesPermissao={AcaoPermissaoPapelUsuarioEnum.CRIACAO_CALENDARIO_CORTE}>
                    <SmartTableButton onClick={onNovoCalendarioCorteClickHandler}>
                      Novo calendário de corte
                    </SmartTableButton>
                  </RbacElement>
                  <ExportCSVButton data={calendariosCorteExcel} filename="calendarios-corte" headers={csvHeaders} />
                </div>
                <SmartTable
                  emptyMessage="Nenhum calendário de corte encontrado"
                  errorMessage="Erro na listagem de calendários de corte"
                  usePagination={true}
                  loadItems={loadItems}
                  columns={smartColumns({ produtos })}
                  items={calendariosCorte}
                  loadingState={loadingState}
                  size={total}
                />
              </BSCard.Body>
            </BSCard>
          </div>
        </div>
      </div>
    </RbacPage>
  );
};

export default CalendariosCortePage;
