import { useCallback, useMemo } from 'react';
import BSBadge from 'react-bootstrap/Badge';
import { useNavigate } from 'react-router-dom';

import { SmartTable } from '../../components/smart-table/smart-table';
import { SmartTableColumnProps } from '../../components/smart-table/smart-table-header';
import { ClassTypesEnum, FormatValueEnum, clienteRename, tipoHistoricoMap } from '../../helpers';
import { useQuerystring } from '../../hooks/router/use-querystring';
import { useAppDispatch, useAppSelector } from '../../store/hooks-redux';
import {
  loadHistoricoCliente,
  selectHistoricoClienteByFilters,
  selectHistoricoClienteLoadingStateByFilters,
  selectTotalHistoricoClienteByFilters,
} from '../historicos-cliente/historico-cliente.redux';

const smartColumns = (): SmartTableColumnProps[] => [
  {
    label: 'Entidade',
    attribute: 'tipo',
    className: 'text-start',
    map: tipoHistoricoMap,
    format: FormatValueEnum.ENUM,
  },
  {
    label: 'Data do evento',
    attribute: 'dataAlteracao',
    className: 'text-start',
    format: FormatValueEnum.DATE_TIME,
  },
  { label: 'Origem', attribute: 'origemAlteracao', className: 'text-start' },
  {
    label: 'Campos alterados',
    attribute: 'alteracao',
    className: 'align-middle',

    format: (alteracao: any) => {
      if (!alteracao) {
        return '-';
      }

      const listaAlteracao = Object.keys(alteracao)
        .map((nameKey: string) => clienteRename[nameKey as keyof typeof clienteRename])
        .filter((el) => el);

      if (listaAlteracao.length > 10) {
        return (
          <h6 className="m-1">
            <BSBadge pill bg={ClassTypesEnum.LIGHT} text={ClassTypesEnum.PRIMARY}>
              Diversos campos alterados
            </BSBadge>
          </h6>
        );
      }

      if (!listaAlteracao.length) {
        return (
          <h6 className="m-1">
            <BSBadge pill bg={ClassTypesEnum.LIGHT} text={ClassTypesEnum.DANGER}>
              Exclusão
            </BSBadge>
          </h6>
        );
      }

      return (
        <div className="d-flex flex-wrap">
          {listaAlteracao.map((alteracao: string, index: number) => (
            <h6 className="m-1" key={index}>
              <BSBadge pill bg={ClassTypesEnum.LIGHT} text={ClassTypesEnum.MUTED}>
                {alteracao}
              </BSBadge>
            </h6>
          ))}
        </div>
      );
    },
  },
];

type HistoricoClienteTabProps = {
  clienteId: string;
};

const HistoricoClienteTab: React.FC<HistoricoClienteTabProps> = ({ clienteId }) => {
  const dispatch = useAppDispatch();
  const useSelector = useAppSelector;
  const navigate = useNavigate();
  const { query } = useQuerystring();

  if (!query.sort) {
    query.sort = '-dataAlteracao';
  }

  const filters = useMemo(() => ({ clienteId, ...query }), [clienteId, query]);

  const total = useSelector((state) => selectTotalHistoricoClienteByFilters(state, filters));
  const historicos = useSelector((state) => selectHistoricoClienteByFilters(state, filters));
  const loadingState = useSelector((state) => selectHistoricoClienteLoadingStateByFilters(state, filters));

  const loadItems = useCallback(
    () => dispatch(loadHistoricoCliente({ params: { clienteId }, query })).catch((error: Error) => error),
    [clienteId, dispatch, query]
  );

  return (
    <SmartTable
      emptyMessage="Nenhum registro encontrado"
      errorMessage="Erro ao listar histórico"
      loadItems={loadItems}
      usePagination={true}
      size={total}
      columns={smartColumns()}
      items={historicos}
      loadingState={loadingState}
      onRowClick={(doc) => navigate(`/clientes/${clienteId}/historicos/${doc._id}/tipo/${doc.tipo}`)}
    />
  );
};

export default HistoricoClienteTab;
