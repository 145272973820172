import { reducerFactory, actionFactory, selectorFactory } from '../../store';

const context = 'dadosComunicacaoCliente';

const { loadOne: loadOneDadosComunicacaoCliente } = actionFactory(context, {
  buildUrlLoadOne: ({ clienteId }) => `/api/clientes/${clienteId}/dados-comunicacao-cliente`,
});

const { selectMapAllByAttribute } = selectorFactory({
  context,
});

export const loadDadosComunicacaoClienteCliente = loadOneDadosComunicacaoCliente;
export const selectMapAllByAttributeDadosComunicacaoCliente = selectMapAllByAttribute;

export const dadosComunicacaoCliente = reducerFactory({ context });
