import { AcaoPermissaoPapelUsuarioEnum } from '@tamborineapps/lib-enums';
import { useCallback, useMemo } from 'react';
import BSCard from 'react-bootstrap/Card';
import { Link, useNavigate } from 'react-router-dom';
import RbacElement from '../../components/role-based-access-control/role-based-access-control-element';
import RbacPage from '../../components/role-based-access-control/role-based-access-control-page';
import { SmartTable } from '../../components/smart-table/smart-table';
import SmartTableButton from '../../components/smart-table/smart-table-button';
import SmartTableFilters from '../../components/smart-table/smart-table-filters/smart-table-filters';
import {
  SmartTableFilterControlTypesEnum,
  SmartTableFilterProps,
} from '../../components/smart-table/smart-table-filters/table-filter.types';
import { SmartTableColumnProps } from '../../components/smart-table/smart-table-header';
import Title from '../../components/title';
import { ApiMultiElementResponse, formatDateTime, mapearERemoverElementosNulosERepetidos } from '../../helpers';
import { useDialog } from '../../hooks/dialog/use-dialog';
import { useQuerystring } from '../../hooks/router/use-querystring';
import { useAppDispatch, useAppSelector } from '../../store/hooks-redux';
import { loadProdutos, selectObjectTodosProdutos } from '../produtos/produtos.redux';
import CadastrarPerguntaFrequenteDialog from './cadastrar-pergunta-frequente-dialog';
import {
  loadPerguntasFrequentes,
  selectPerguntasFrequentesByFilters,
  selectPerguntasFrequentesLoadingStateByFilters,
  selectTotalPerguntasFrequentesByFilters,
} from './perguntas-frequentes.redux';

type dataHoraProps = {
  criacao: Date;
  atualizacao: Date;
};

const smartColumns = ({ produtos }: { produtos: any }): SmartTableColumnProps[] => [
  {
    label: 'Data da Criação',
    attribute: 'dataHora',
    format: (dataHora: dataHoraProps) => {
      if (!dataHora) {
        return ' ';
      }
      const dataCriacao = dataHora.criacao;
      return formatDateTime(dataCriacao);
    },
    className: 'text-center',
  },
  {
    label: 'Data da alteração ',
    attribute: 'dataHora',
    sortable: true,
    format: (dataHora: dataHoraProps) => {
      if (!dataHora) {
        return ' ';
      }
      const dataAlteracao = dataHora.atualizacao;
      return formatDateTime(dataAlteracao);
    },
    className: 'text-center',
  },
  {
    label: 'Produto',
    attribute: 'produto',
    format(produto) {
      if (!produto) {
        return '-';
      }

      return (
        <Link onClick={(e) => e.stopPropagation()} to={`/produtos/${produto?._id}`}>
          {produtos[produto?._id]?.nome}
        </Link>
      );
    },
    className: 'text-center',
    sortable: true,
  },
  {
    label: 'Tópicos ',
    attribute: 'topicos',
    sortable: true,
    format(topicos) {
      if (!topicos) {
        return '-';
      }
      return topicos.map((item: any, i: any) => <li key={i}>{item?.assunto}</li>);
    },
    className: 'text-center',
  },
];

const smartFilters = ({ produtosMap }: { produtosMap: any }): SmartTableFilterProps[] => [
  {
    attribute: 'produtoId',
    label: 'Produto',
    controlType: SmartTableFilterControlTypesEnum.SELECT,
    map: produtosMap,
  },
];

const PerguntasFrequentesPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const useSelector = useAppSelector;

  const { query, maxItemsQuery } = useQuerystring();
  const navigate = useNavigate();

  const { showDialog, closeDialog } = useDialog();

  const filters = useMemo(() => ({ ...query }), [query]);
  const perguntasFrequentes = useSelector((state) => selectPerguntasFrequentesByFilters(state, filters));
  const total = useSelector((state) => selectTotalPerguntasFrequentesByFilters(state, filters));
  const loadingState = useSelector((state) => selectPerguntasFrequentesLoadingStateByFilters(state, filters));
  const produtos = useSelector((state) => selectObjectTodosProdutos(state));

  const loadEntidadesComplementares = useCallback(
    (oerguntasFrequentes: any[]) => {
      const produtos = mapearERemoverElementosNulosERepetidos(oerguntasFrequentes, 'produto');

      const produtosIds = mapearERemoverElementosNulosERepetidos(produtos, '_id');
      dispatch(loadProdutos({ query: { _id: { in: produtosIds }, ...maxItemsQuery } })).catch((error: Error) => error);
    },
    [dispatch, maxItemsQuery]
  );

  const loadItems = useCallback(
    () =>
      dispatch(loadPerguntasFrequentes({ query }))
        .then(({ payload: { data } }: ApiMultiElementResponse) => loadEntidadesComplementares(data))
        .catch((error: Error) => error),
    [dispatch, loadEntidadesComplementares, query]
  );

  const produtosMap = Object.entries(produtos || {}).reduce(
    (acc, [produtoId, produto]) => ({
      ...acc,
      [produtoId]: `${produto.codigo} - ${produto.nome}`,
    }),
    {}
  );

  const onPerguntasFrequentesClickHandler = () => {
    showDialog({ component: <CadastrarPerguntaFrequenteDialog closeDialog={closeDialog} reload={loadItems} /> });
  };

  return (
    <RbacPage acoesPermissao={AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_PERGUNTA_FREQUENTE}>
      <div className="d-flex flex-column h-100">
        <Title>Perguntas frequentes</Title>

        <div className="row">
          <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-12 mb-4">
            <SmartTableFilters filters={smartFilters({ produtosMap })} />
          </div>

          <div className="col-xxl-10 col-xl-9 col-lg-8 col-md-12 mb-4">
            <BSCard>
              <BSCard.Body>
                <RbacElement acoesPermissao={AcaoPermissaoPapelUsuarioEnum.CRIACAO_PERGUNTA_FREQUENTE}>
                  <div className="d-flex align-items-center mb-2">
                    <SmartTableButton onClick={onPerguntasFrequentesClickHandler}>
                      Nova pergunta frequente
                    </SmartTableButton>
                  </div>
                </RbacElement>

                <SmartTable
                  emptyMessage="Nenhuma pergunta frequente encontrada"
                  errorMessage="Erro na listagem de perguntas frequentes "
                  usePagination={true}
                  loadItems={loadItems}
                  columns={smartColumns({ produtos })}
                  items={perguntasFrequentes}
                  loadingState={loadingState}
                  size={total}
                  onRowClick={(doc) => navigate(`/perguntas-frequentes/${doc._id}/`)}
                />
              </BSCard.Body>
            </BSCard>
          </div>
        </div>
      </div>
    </RbacPage>
  );
};

export default PerguntasFrequentesPage;
