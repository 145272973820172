import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { SmartTable } from '../../components/smart-table/smart-table';
import { SmartTableColumnProps } from '../../components/smart-table/smart-table-header';
import { FormatValueEnum } from '../../helpers';
import { useQuerystring } from '../../hooks/router/use-querystring';
import { useAppDispatch, useAppSelector } from '../../store/hooks-redux';
import {
  loadFaturas,
  selectFaturasByFilters,
  selectFaturasLoadingStateByFilters,
  selectTotalFaturasByFilters,
} from '../faturas/faturas.redux';

const smartColumns = (): SmartTableColumnProps[] => [
  {
    label: 'Início',
    attribute: 'dataInicioPeriodo',
    sortable: true,
    format: FormatValueEnum.DATA,
    className: 'text-center',
  },
  {
    label: 'Corte',
    attribute: 'dataCorte',
    sortable: true,
    className: 'text-center',
    format: FormatValueEnum.DATA,
  },
  {
    label: 'Prévia',
    attribute: 'dataPrevia',
    sortable: true,
    className: 'text-center',
    format: FormatValueEnum.DATA,
  },
  {
    label: 'Vencimento',
    attribute: 'dataVencimento',
    sortable: true,
    className: 'text-center',
    format: FormatValueEnum.DATA,
  },
  {
    label: 'Total (R$)',
    attribute: 'valorTotal',
    sortable: true,
    className: 'text-end',
    format: FormatValueEnum.BRL,
  },
  {
    label: 'Está fechada',
    attribute: 'estaFechada',
    className: 'text-center',
    format: FormatValueEnum.BOOL,
  },
];

type FaturasContaCartaoTabProps = {
  contaCartaoId: string;
};

const FaturasContaCartaoTab: React.FC<FaturasContaCartaoTabProps> = ({ contaCartaoId }) => {
  const dispatch = useAppDispatch();
  const useSelector = useAppSelector;

  const navigate = useNavigate();
  const { query } = useQuerystring();

  if (!query.sort) {
    query.sort = '-dataInicioPeriodo';
  }

  const filters = useMemo(() => ({ ...query, contaCartaoId }), [contaCartaoId, query]);
  const faturas = useSelector((state) => selectFaturasByFilters(state, filters));
  const loadingState = useSelector((state) => selectFaturasLoadingStateByFilters(state, filters));
  const total = useSelector((state) => selectTotalFaturasByFilters(state, filters));

  const _loadFaturas = useCallback(
    () => dispatch(loadFaturas({ params: { contaCartaoId }, query })).catch((error: Error) => error),
    [contaCartaoId, dispatch, query]
  );

  return (
    <div className="row">
      <SmartTable
        emptyMessage="Nenhuma fatura encontrada"
        errorMessage="Erro ao listar faturas"
        size={total}
        columns={smartColumns()}
        items={faturas}
        loadItems={_loadFaturas}
        loadingState={loadingState}
        usePagination={true}
        onRowClick={(doc) => navigate(`/contas-cartao/${doc.contaCartao}/faturas/${doc._id}`)}
      />
    </div>
  );
};

export default FaturasContaCartaoTab;
