import { useCallback, useMemo } from 'react';
import BSBadge from 'react-bootstrap/Badge';
import { Link, useNavigate } from 'react-router-dom';
import { SmartTable } from '../../components/smart-table/smart-table';
import SmartTableFilters from '../../components/smart-table/smart-table-filters/smart-table-filters';
import {
  SmartTableFilterControlTypesEnum,
  SmartTableFilterProps,
} from '../../components/smart-table/smart-table-filters/table-filter.types';
import { SmartTableColumnProps } from '../../components/smart-table/smart-table-header';
import {
  ApiMultiElementResponse,
  ClassTypesEnum,
  FormatValueEnum,
  formatBRL,
  formatCartao,
  mapearERemoverElementosNulosERepetidos,
  tipoTransacaoLabelMap,
} from '../../helpers';
import { useQuerystring } from '../../hooks/router/use-querystring';
import { useAppDispatch, useAppSelector } from '../../store/hooks-redux';
import { loadCartoes, selectObjectTodosCartoes } from '../cartoes/cartoes.redux';
import {
  loadPedidosAutorizacaoContaCartao,
  selectPedidosAutorizacaoByFilters,
  selectPedidosAutorizacaoLoadingStateByFilters,
  selectTotalPedidosAutorizacaoByFilters,
} from '../pedidos-autorizacao/pedidos-autorizacao.redux';

const smartFilters = (): SmartTableFilterProps[] => [
  {
    attribute: 'cartao',
    label: 'Final do cartão',
    controlType: SmartTableFilterControlTypesEnum.TEXT_INPUT,
    minLength: 3,
  },
  {
    label: 'Autorizado',
    attribute: 'foiAutorizado',
    controlType: SmartTableFilterControlTypesEnum.BOOL_RADIO,
  },
  {
    label: 'Data do pedido',
    attribute: 'dataHoraPedido',
    controlType: SmartTableFilterControlTypesEnum.DATE_INPUT,
  },
  {
    label: 'Operacao',
    attribute: 'operacao',
    controlType: SmartTableFilterControlTypesEnum.SELECT,
    map: tipoTransacaoLabelMap,
  },
];

const smartColumns = ({ cartoes }: { cartoes: any }): SmartTableColumnProps[] => [
  {
    label: 'Data',
    attribute: 'dataHoraPedido',
    className: 'text-center',
    sortable: true,
    format: FormatValueEnum.DATE_TIME,
  },
  {
    label: 'Motivo',
    attribute: 'motivo',
    className: 'text-center',
    sortable: true,
  },
  {
    label: 'Autorizado',
    attribute: 'foiAutorizado',
    className: 'text-center',
    sortable: true,
    format: FormatValueEnum.BOOL,
  },
  {
    label: 'Estabelecimento',
    attribute: 'mensagem',
    className: 'text-center',
    sortable: true,
    format: (mensagem: any) => mensagem.nomeLojista,
  },
  {
    label: 'Operação',
    attribute: 'operacao',
    className: 'text-center',
    sortable: true,
    format: FormatValueEnum.ENUM,
    map: tipoTransacaoLabelMap,
  },
  {
    label: 'Cartão',
    className: 'text-center',
    format: (_, doc) => {
      if (!doc?.cartao) {
        return '-';
      }

      const cartao = cartoes[doc.cartao];

      return cartao ? (
        <Link
          onClick={(e) => e.stopPropagation()}
          to={`/contas-cartao/${cartoes[doc.cartao]?.contaCartao}/cartoes/${doc.cartao}`}
        >
          {formatCartao(cartoes[doc.cartao]?.numeroTruncado)}
        </Link>
      ) : (
        '-'
      );
    },
  },
  {
    label: 'Valor',
    attribute: 'mensagem',
    className: 'text-center',
    sortable: true,
    format: ({ valorFaturamento }: { valorFaturamento: number }) =>
      !valorFaturamento ? '-' : formatBRL(valorFaturamento),
  },
  {
    label: 'Autorizado',
    attribute: 'foiAutorizado',
    className: 'text-center',
    format: (foiAutorizado: boolean) => {
      return (
        <h5 className="m-0">
          <BSBadge pill bg={foiAutorizado ? ClassTypesEnum.SUCCESS : ClassTypesEnum.DANGER}>
            {foiAutorizado ? 'Autorizado' : 'Não autorizado'}
          </BSBadge>
        </h5>
      );
    },
  },
];
type PedidosAutorizacaoContaCartaoTabProps = {
  contaCartaoId: string;
};

const PedidosAutorizacaoContaCartaoTab: React.FC<PedidosAutorizacaoContaCartaoTabProps> = ({ contaCartaoId }) => {
  const dispatch = useAppDispatch();
  const useSelector = useAppSelector;

  const navigate = useNavigate();
  const { query, maxItemsQuery } = useQuerystring();

  if (!query.sort) {
    query.sort = '-dataHoraPedido';
  }

  const filters = useMemo(() => ({ ...query, contaCartaoId }), [contaCartaoId, query]);
  const pedidosAutorizacao = useSelector((state) => selectPedidosAutorizacaoByFilters(state, filters));
  const total = useSelector((state) => selectTotalPedidosAutorizacaoByFilters(state, filters));
  const loadingState = useSelector((state) => selectPedidosAutorizacaoLoadingStateByFilters(state, filters));

  const cartoes = useSelector((state) => selectObjectTodosCartoes(state));

  const loadEntidadesComplementares = useCallback(
    (pedidosAutorizacao: any[]) => {
      const cartoes = mapearERemoverElementosNulosERepetidos(pedidosAutorizacao, 'cartao');

      dispatch(loadCartoes({ query: { _id: { in: cartoes }, ...maxItemsQuery } })).catch((error: Error) => error);
    },
    [dispatch, maxItemsQuery]
  );

  const loadItems = useCallback(() => {
    dispatch(loadPedidosAutorizacaoContaCartao({ params: { contaCartaoId }, query }))
      .then(({ payload: { data } }: ApiMultiElementResponse) => loadEntidadesComplementares(data))
      .catch((error: Error) => error);
  }, [contaCartaoId, dispatch, loadEntidadesComplementares, query]);

  return (
    <>
      <div className="row">
        <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-12 mb-4">
          <SmartTableFilters filters={smartFilters()} />
        </div>

        <div className="col-xxl-10 col-xl-9 col-lg-8 col-md-12 mb-4">
          <SmartTable
            emptyMessage="Nenhum pedido encontrado"
            errorMessage="Erro ao listar pedidos"
            loadItems={loadItems}
            usePagination={true}
            size={total}
            columns={smartColumns({ cartoes })}
            items={pedidosAutorizacao}
            loadingState={loadingState}
            onRowClick={(doc) => navigate(`pedidos-autorizacao/${doc._id}`)}
          />
        </div>
      </div>
    </>
  );
};

export default PedidosAutorizacaoContaCartaoTab;
