import { reducerFactory, actionFactory, selectorFactory } from '../../store';

const context = 'credenciaisCliente';

const { createOne, loadMany, loadOne, updateOne } = actionFactory(context, {
  buildUrlLoadOne: ({ credencialClienteId }) => `/api/credenciais-cliente/${credencialClienteId}`,
  buildUrlCreateOne: () => '/api/credenciais-cliente',
  buildUrlLoadMany: () => '/api/credenciais-cliente',
  buildUrlUpdateOne: ({ credencialClienteId }) => `/api/credenciais-cliente/${credencialClienteId}`,
});

const { selectManyByFilters, selectTotalByFilters, selectLoadingStateByFilters, selectObjectAllIds, selectOneById } =
  selectorFactory({
    context,
  });

export const loadCredencialCliente = loadOne;
export const loadCredenciaisCliente = loadMany;
export const createCredencialCliente = createOne;
export const updateCredencialCliente = updateOne;

export const selectCredencialClienteById = selectOneById;
export const selectObjectTodosCredenciaisCliente = selectObjectAllIds;
export const selectCredenciaisClienteByFilters = selectManyByFilters;
export const selectTotalCredenciaisClienteByFilters = selectTotalByFilters;
export const selectCredenciaisClienteLoadingStateByFilters = selectLoadingStateByFilters;

export const credenciaisCliente = reducerFactory({ context });
