import { AcaoPermissaoPapelUsuarioEnum } from '@tamborineapps/lib-enums';
import { useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { DetailCard } from '../../components/details/datail-card';
import DetailElement from '../../components/details/detail-element';
import DetailTitle from '../../components/details/detail-title';
import DetailSubTitle from '../../components/details/detail-subtitle';
import { Loading } from '../../components/loading';
import RbacElement from '../../components/role-based-access-control/role-based-access-control-element';
import RbacPage from '../../components/role-based-access-control/role-based-access-control-page';
import SmartTableButton from '../../components/smart-table/smart-table-button';
import { useAppDispatch, useAppSelector } from '../../store/hooks-redux';
import { FormatValueEnum } from '../../helpers';
import { useDialog } from '../../hooks/dialog/use-dialog';
import {
  loadGrupoCredenciaisCliente,
  selectGrupoCredencialClienteById,
  selectGruposCredenciaisClienteLoadingStateByFilters,
} from './grupo-credenciais-cliente.redux';
import CadastrarCredencialClienteDialog from './cadastrar-credencial-cliente.dialog';
import { CredenciaisClientesTable } from './credenciais-cliente-tab';
import { loadCredenciaisCliente } from './credenciais-cliente.redux';

const DetalhesGrupoCredencialPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const useSelector = useAppSelector;
  const { closeDialog, showDialog } = useDialog();
  const params = useParams();
  const grupoCredenciaisClienteId = params.grupoCredencialId as string;
  const filters = useMemo(() => ({ grupoCredenciaisClienteId }), [grupoCredenciaisClienteId]);
  const grupoClienteCredential = useSelector((state) =>
    selectGrupoCredencialClienteById(state, grupoCredenciaisClienteId)
  );
  const loadingState = useSelector((state) => selectGruposCredenciaisClienteLoadingStateByFilters(state, filters));

  useEffect(() => {
    dispatch(loadGrupoCredenciaisCliente({ grupoCredenciaisClienteId })).catch((error: Error) => error);
  }, [dispatch, grupoCredenciaisClienteId]);

  const loadItems = useCallback(
    () => dispatch(loadCredenciaisCliente({ query: { grupoCredenciaisClienteId } })).catch((error: Error) => error),
    [dispatch, grupoCredenciaisClienteId]
  );

  const onNovoCredencialClienteHandler = () => {
    showDialog({
      component: (
        <CadastrarCredencialClienteDialog
          grupoCredenciaisClienteId={grupoCredenciaisClienteId}
          closeDialog={closeDialog}
          reload={loadItems}
        />
      ),
    });
  };

  if (!grupoClienteCredential) {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <Loading notFoundMessage="Grupo credencial cliente não encontrado" loadingState={loadingState} />
      </div>
    );
  }

  return (
    <RbacPage acoesPermissao={AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_GRUPO_CLIENT_CREDENTIALS}>
      <DetailCard>
        <>
          <div className="row mb-5">
            <DetailTitle>Detalhes do grupo {grupoClienteCredential?.nome}</DetailTitle>
          </div>

          <div className="row mb-5">
            <div className="col-lg-4 col-md-6 mb-4">
              <DetailElement descricao="Ativo" valor={grupoClienteCredential?.ativo} format={FormatValueEnum.BOOL} />
            </div>
          </div>

          <div className="mb-3">
            <DetailSubTitle>Credenciais de clientes</DetailSubTitle>
          </div>
          <div className="d-flex align-items-center mb-2">
            <RbacElement acoesPermissao={AcaoPermissaoPapelUsuarioEnum.CRIACAO_CLIENT_CREDENTIALS}>
              <SmartTableButton onClick={onNovoCredencialClienteHandler}>Novo client credential </SmartTableButton>
            </RbacElement>
          </div>

          <CredenciaisClientesTable grupoId={grupoCredenciaisClienteId} />
        </>
      </DetailCard>
    </RbacPage>
  );
};

export default DetalhesGrupoCredencialPage;
