import {
  AcaoEmbossingEnum,
  AcaoEnvioFaturaEnum,
  BancoCedenteBoletoEnum,
  BandeiraEnum,
  CodigoBaixaDevolucaoBoletoEnum,
  ContextoProtocoloProcessamentoLoteEnum,
  DesignCartaoEnum,
  FormaCadastramentoBoletoEnum,
  FormaEnvioCartaoEnum,
  FormaEnvioFaturaEnum,
  FuncaoAtivaContaCartaoEnum,
  LabelOperacaoTransacaoEnum,
  LocalEntregaCartaoEnum,
  ModeloCalculoPagamentoMinimoEnum,
  NaturezaTransacaoEnum,
  OrigemComercialContaCartaoEnum,
  PeriodicidadeEnum,
  ProdutoBandeiraEnum,
  ResultadoProcessamentoDocumentacaoClienteEnum,
  RotinaContaCartaoEnum,
  SexoEnum,
  StatusProcessoAlteracaoDadosUsuarioClienteEnum,
  StatusContaCartaoEnum,
  StatusEmbossingEnum,
  StatusProcessamentoDocumentacaoProcessoAlteracaoDadosUsuarioClienteEnum,
  StatusProcessamentoDocumentacaoSolicitacaoAberturaContaEnum,
  StatusProcessamentoEnum,
  StatusProcessamentoLivenessProcessoAlteracaoDadosUsuarioClienteEnum,
  StatusSolicitacaoAberturaContaEnum,
  StatusSolicitacaoClienteEnum,
  StatusSolicitacaoParcelamentoEnum,
  SubOrigemEnum,
  SubTaxaEnum,
  SubtipoCnabEnum,
  TipoArredondamentoDoacaoEnum,
  TipoAlteracaoHistoricoEnum,
  TipoBeneficioEnum,
  TipoBloqueioCartaoEnum,
  TipoBloqueioContaCartaoEnum,
  TipoCartaoEnum,
  TipoCnabEnum,
  TipoCodigoConfirmacaoClienteEnum,
  TipoContaBancariaEnum,
  TipoContratosTermosUsoEnum,
  TipoCreditoEnum,
  TipoDocumentoClienteEnum,
  TipoEnderecoEnum,
  TipoEventoContabilEnum,
  TipoFeriadoEnum,
  TipoHistoricoEnum,
  TipoProcessamentoCalendarioCorteEnum,
  TipoProtocoloProcessamentoLoteEnum,
  TipoRelatorioOperacaoEnum,
  TipoRoteiroContabilEnum,
  TipoTarifaEnum,
  TipoTitularidadeCartaoEnum,
  OperacaoTransacaoEnum,
  RespostaAutorizadorEnum,
} from '@tamborineapps/lib-enums';
import { ClassTypesEnum } from './enums';

export const boolLabelMap = {
  true: 'Sim',
  false: 'Não',
};

export const trimestreLabelMap = {
  1: '1º trimestre',
  2: '2º trimestre',
  3: '3º trimestre',
  4: '4º trimestre',
};

export const moedaLabelMap = {
  840: 'USD',
  978: 'EUR',
  986: 'BRL',
};

export const moedaMap = {
  dolar: {
    label: 'Dólar',
    value: 'USD',
    numerico: '840',
    simbolo: '$',
  },
};

export const mesLabelMap = {
  1: 'Janeiro',
  2: 'Fevereiro',
  3: 'Março',
  4: 'Abril',
  5: 'Maio',
  6: 'Junho',
  7: 'Julho',
  8: 'Agosto',
  9: 'Setembro',
  10: 'Outubro',
  11: 'Novembro',
  12: 'Dezembro',
};

export const tipoAlteracaoHistoricoMap = {
  [TipoAlteracaoHistoricoEnum.CADASTRO]: 'Cadastro',
  [TipoAlteracaoHistoricoEnum.ATUALIZACAO]: 'Atualização',
  [TipoAlteracaoHistoricoEnum.REMOCAO]: 'Remoção',
};

export const tipoHistoricoMap = {
  [TipoHistoricoEnum.CARTAO]: 'Cartão',
  [TipoHistoricoEnum.CLIENTE]: 'Cliente',
  [TipoHistoricoEnum.CONTA_CARTAO]: 'Conta cartão',
  [TipoHistoricoEnum.DADOS_COMUNICACAO_CLIENTE]: 'Dados comunicação cliente',
  [TipoHistoricoEnum.ENDERECO]: 'Endereço',
  [TipoHistoricoEnum.FATURA]: 'Fatura',
};

export const tipoPessoaLabelMap = {
  PF: 'Física',
  PJ: 'Juridica',
};

export const utilizacaoLabelMap = { M: 'Manual', S: 'Sistema' };

// TODO: Refatorar para utilizar enum
export const tipoIdentidadeVisualLabelMap = {
  CREDITO_PURO_CARTAO_BALEIA: 'Crédito puro cartão baleia',
};

export const acaoEmbossingLabelMap = {
  [AcaoEmbossingEnum.NAO_EMBOSSAR]: 'Não embossar',
  [AcaoEmbossingEnum.EMBOSSAR]: 'Embossar',
  [AcaoEmbossingEnum.EMITIR_SEGUNDA_VIA_COM_TROCA_SENHA]: '2ª via de cartão com troca de senha',
  [AcaoEmbossingEnum.EMITIR_SEGUNDA_VIA_SEM_TROCA_SENHA]: '2ª via de cartão sem troca de senha',
  [AcaoEmbossingEnum.ENVIAR_CARTA_SENHA]: 'Enviar Carta',
};

export const statusEmbossingLabelMap = {
  [StatusEmbossingEnum.EMBOSSADO]: 'Embossado',
  [StatusEmbossingEnum.EM_PROCESSAMENTO]: 'Em processamento',
  [StatusEmbossingEnum.ERRO_EMBOSSING]: 'Erro no embossing',
  [StatusEmbossingEnum.NAO_EMBOSSADO]: 'Não embossado',
};

export const statusEmbossingLabelColorMap = {
  [StatusEmbossingEnum.EMBOSSADO]: ClassTypesEnum.SUCCESS,
  [StatusEmbossingEnum.EM_PROCESSAMENTO]: ClassTypesEnum.WARNING,
  [StatusEmbossingEnum.ERRO_EMBOSSING]: ClassTypesEnum.DANGER,
  [StatusEmbossingEnum.NAO_EMBOSSADO]: ClassTypesEnum.SECONDARY,
};

export const acaoEnvioFaturaLabelMap = {
  [AcaoEnvioFaturaEnum.ENVIAR]: 'Enviar fatura ao cliente',
  [AcaoEnvioFaturaEnum.NAO_ENVIAR]: 'Não enviar fatura ao cliente',
  [AcaoEnvioFaturaEnum.ENVIO_OBRIGATORIO]: 'Envio obrigatorio',
};

export const bancoCedenteLabelMap = {
  [BancoCedenteBoletoEnum.BMG]: 'BMG',
};

export const codigoBaixaDevolucaoBoletoLabelMap = {
  [CodigoBaixaDevolucaoBoletoEnum.DEVOLVER]: 'Devolver',
  [CodigoBaixaDevolucaoBoletoEnum.NAO_DEVOLVER]: 'Não devolver',
  [CodigoBaixaDevolucaoBoletoEnum.UTILIZAR_PERFIL_CEDENTE]: 'Utilizar perfil cedente',
};

export const formaCadastramentoBoletoLabelMap = {
  [FormaCadastramentoBoletoEnum.COBRANCA_REGISTRADA]: 'Cobrança registrada (rápida e eletrônica com registro)',
  [FormaCadastramentoBoletoEnum.COBRANCA_SEM_REGISTRO]: 'Cobrança sem registro',
};

export const formaEnvioCartaoLabelMap = {
  [FormaEnvioCartaoEnum.ENVIAR_COM_AR]: 'Enviar com AR',
  [FormaEnvioCartaoEnum.ENVIAR_COM_CARTA_SIMPLES]: 'Enviar com Carta simples',
  [FormaEnvioCartaoEnum.RETER_CARTAO]: 'Reter cartão',
  [FormaEnvioCartaoEnum.COURRIER]: 'Courrier',
  [FormaEnvioCartaoEnum.EMISSAO_INSTANTANEA]: 'Emissão instantânea',
  [FormaEnvioCartaoEnum.EM_MAOS]: 'Em mãos',
};

export const formaEnvioFaturaLabelMap = {
  [FormaEnvioFaturaEnum.CORREIOS_AR]: 'Impressa com AR',
  [FormaEnvioFaturaEnum.CORREIOS_POSTAGEM_SIMPLES]: 'Impressa com carta registrada',
  [FormaEnvioFaturaEnum.EMAIL]: 'Por e-mail',
  [FormaEnvioFaturaEnum.SMS]: 'Por SMS',
  [FormaEnvioFaturaEnum.ONLINE]: 'Online',
  [FormaEnvioFaturaEnum.CORREIOS_EMAIL]: 'Postagem simples por correio + e-mail',
  [FormaEnvioFaturaEnum.CORREIOS_AR_EMAIL]: 'Postagem por correio com AR + e-mail',
};

export const funcaoAtivaContaCartaoLabelMap = {
  [FuncaoAtivaContaCartaoEnum.CREDITO]: 'Credito',
  [FuncaoAtivaContaCartaoEnum.DEBITO]: 'Debito',
  [FuncaoAtivaContaCartaoEnum.DEBITO_CREDITO]: 'Debito e Credito',
};

// TODO: utilizar enum
export const codigoRespostaAutorizadorLabelMap = {
  [RespostaAutorizadorEnum.APROVADO]: 'Aprovado',
  [RespostaAutorizadorEnum.APROVADO_NAO_RECUSADA]: 'Aprovado (não recusada)',
  [RespostaAutorizadorEnum.ERRO_PROCESSAMENTO]: 'Erro de processamento',
  [RespostaAutorizadorEnum.REPROVADO_AUTORIZACAO_CARD_ON_FILE_CVC_VERIFICADO]:
    'Reprovado: autorização de card on file com CVC verificado',
  REPROVADO_AUTORIZACAO_ECOMMERCE_CARTAO_EM_CARD_ON_FILE:
    'Reprovado: autorização e-commerce com cartão em card on file',
  [RespostaAutorizadorEnum.REPROVADO_AUTORIZACAO_TARJA_MAGNETICA_SEM_PIN]:
    'Reprovado: autorização com tarja magnética sem PIN',
  [RespostaAutorizadorEnum.REPROVADO_BENEFICIOS_INATIVOS_SEM_SALDO]: 'Reprovado: benefícios inativos sem saldo',
  [RespostaAutorizadorEnum.REPROVADO_BENEFICIOS_SEM_SALDO]: 'Reprovado: benefícios sem saldo',
  [RespostaAutorizadorEnum.REPROVADO_CAMPOS_RECEBIDOS_INCONSISTENTES]: 'Reprovado: campos recebidos inconsistentes',
  [RespostaAutorizadorEnum.REPROVADO_CAMPO_RECEBIDO_INVALIDO]: 'Reprovado: campo recebido inválido',
  [RespostaAutorizadorEnum.REPROVADO_CARTAO_BLOQUEADO]: 'Reprovado: cartão bloqueado',
  [RespostaAutorizadorEnum.REPROVADO_CARTAO_BLOQUEADO_CANCELADO]: 'Reprovado: cartão bloqueado e cancelado',
  [RespostaAutorizadorEnum.REPROVADO_CARTAO_BLOQUEADO_CVC_INCORRETO]: 'Reprovado: cartão bloqueado por CVC incorreto',
  [RespostaAutorizadorEnum.REPROVADO_CARTAO_BLOQUEADO_DANIFICADO]: 'Reprovado: cartão bloqueado por estar danificado',
  [RespostaAutorizadorEnum.REPROVADO_CARTAO_BLOQUEADO_ECOMMERCE]: 'Reprovado: cartão bloqueado em e-commerce',
  [RespostaAutorizadorEnum.REPROVADO_CARTAO_BLOQUEADO_INFRACAO_SEGURANCA]:
    'Reprovado: cartão bloqueado por infração de segurança',
  [RespostaAutorizadorEnum.REPROVADO_CARTAO_BLOQUEADO_PERDA]: 'Reprovado: cartão bloqueado por perda',
  [RespostaAutorizadorEnum.REPROVADO_CARTAO_BLOQUEADO_ROUBO]: 'Reprovado: cartão bloqueado por roubo',
  [RespostaAutorizadorEnum.REPROVADO_CARTAO_BLOQUEADO_SENHA_INCORRETA]:
    'Reprovado: cartão bloqueado por senha incorreta',
  [RespostaAutorizadorEnum.REPROVADO_CARTAO_BLOQUEADO_VENCIDO]: 'Reprovado: cartão bloqueado por vencimento',
  [RespostaAutorizadorEnum.REPROVADO_CARTAO_JA_EM_CARD_ON_FILE_LOJISTA_AUTORIZADO]:
    'Reprovado: cartão já em card on file com lojista autorizado',
  REPROVADO_CARTAO_JA_EM_CARD_ON_FILE_LOJISTA_NAO_AUTORIZADO:
    'Reprovado: cartão já em card on file com lojista não autorizado',
  [RespostaAutorizadorEnum.REPROVADO_CARTAO_NAO_CADASTRADO_CARD_ON_FILE]:
    'Reprovado: cartão não cadastrado em card on file',
  [RespostaAutorizadorEnum.REPROVADO_CARTAO_NAO_PERMITE_COMPRA_CONTACLESS]:
    'Reprovado: cartão não permite compra por contactless',
  [RespostaAutorizadorEnum.REPROVADO_CARTAO_NAO_PERMITE_COMPRA_INTERNACIONAL]:
    'Reprovado: cartão não permite compra internacional',
  [RespostaAutorizadorEnum.REPROVADO_CARTAO_NAO_PERMITE_COMPRA_ONLINE]: 'Reprovado: cartão não permite compra online',
  [RespostaAutorizadorEnum.REPROVADO_CARTAO_NAO_PERMITE_COMPRA_ONLINE_INTERNACIONAL]:
    'Reprovado: cartão não permite compra online internacional',
  [RespostaAutorizadorEnum.REPROVADO_CARTAO_VIRTUAL_COMPRA_PRESENCIAL]:
    'Reprovado: cartão virtual usado para compra presencial',
  [RespostaAutorizadorEnum.REPROVADO_CATEGORIA_BENEFICIO_NAO_ENCONTRADA_MCC]:
    'Reprovado: categoria de benefício não encontrada (MCC)',
  REPROVADO_CATEGORIA_BENEFICIO_NAO_ENCONTRADA_PARA_BENEFICIO:
    'Reprovado: categoria de benefício não encontrada para benefício',
  [RespostaAutorizadorEnum.REPROVADO_COMPRA_ONLINE_CVC_PRESENCIAL]: 'Reprovado: compra online com CVC presencial',
  [RespostaAutorizadorEnum.REPROVADO_COMPRA_PRESENCIAL_CVC_ONLINE]: 'Reprovado: compra presencial com CVC online',
  [RespostaAutorizadorEnum.REPROVADO_COMPRA_PRESENCIAL_SEM_CVC_PRESENCIAL]:
    'Reprovado: compra presencial sem CVC presencial',
  [RespostaAutorizadorEnum.REPROVADO_CONSULTA_SALDO_VALOR_NAO_NULO]: 'Reprovado: consulta de saldo com valor não nulo',
  [RespostaAutorizadorEnum.REPROVADO_CONTADOR_ATC_INFERIOR_AO_ESPERADO]: 'Reprovado: contador ATC inferior ao esperado',
  [RespostaAutorizadorEnum.REPROVADO_CONTA_CARTAO_BLOQUEADA]: 'Reprovado: conta de cartão bloqueada',
  [RespostaAutorizadorEnum.REPROVADO_CONTA_CARTAO_BLOQUEADA_EXCESSO_LIMITE]:
    'Reprovado: conta de cartão bloqueada por excesso de limite',
  [RespostaAutorizadorEnum.REPROVADO_CONTA_CARTAO_BLOQUEADA_INFRACAO_SEGURANCA]:
    'Reprovado: conta de cartão bloqueada por infração de segurança',
  [RespostaAutorizadorEnum.REPROVADO_CONTA_CARTAO_NAO_POSSUI_BENEFICIO]:
    'Reprovado: conta de cartão não possui benefício',
  REPROVADO_CONTA_CARTAO_NAO_POSSUI_PROPRIEDADE_BENEFICIOS:
    'Reprovado: conta de cartão não possui propriedade de benefícios',
  [RespostaAutorizadorEnum.REPROVADO_CVC_IMPRESSO_INVALIDO]: 'Reprovado: CVC impresso inválido',
  [RespostaAutorizadorEnum.REPROVADO_DATA_VENCIMENTO_INCORRETA]: 'Reprovado: data de vencimento incorreta',
  [RespostaAutorizadorEnum.REPROVADO_ENTIDADE_NAO_ENCONTRADA]: 'Reprovado: entidade(s) não encontrada(s)',
  [RespostaAutorizadorEnum.REPROVADO_ENTRADA_PAN_INVALIDA]: 'Reprovado: entrada PAN inválida',
  [RespostaAutorizadorEnum.REPROVADO_EXCEDEU_TENTATIVAS_CVC_INCORRETO]:
    'Reprovado: excedeu tentativas com CVC incorreto',
  [RespostaAutorizadorEnum.REPROVADO_EXCEDEU_TENTATIVAS_PIN_INCORRETO]:
    'Reprovado: excedeu tentativas com PIN incorreto',
  [RespostaAutorizadorEnum.REPROVADO_FALHA_VERIFICACAO_CVC1]: 'Reprovado: falha na verificação do CVC1',
  [RespostaAutorizadorEnum.REPROVADO_FALHA_VERIFICACAO_CVC2]: 'Reprovado: falha na verificação do CVC2',
  [RespostaAutorizadorEnum.REPROVADO_FUNCIONALIDADE_NAO_IMPLEMENTADA]: 'Reprovado: funcionalidade não implementada',
  [RespostaAutorizadorEnum.REPROVADO_LIMITE_CREDITO_INSUFICIENTE]: 'Reprovado: limite de crédito insuficiente',
  [RespostaAutorizadorEnum.REPROVADO_LIMITE_SAQUE_INSUFICIENTE]: 'Reprovado: limite de saque insuficiente',
  [RespostaAutorizadorEnum.REPROVADO_LOJISTA_BLOQUEADO]: 'Reprovado: lojista bloqueado',
  [RespostaAutorizadorEnum.REPROVADO_PEDIDO_ORIGEM_NAO_AUTORIZADO]: 'Reprovado: pedido de origem não autorizado',
  [RespostaAutorizadorEnum.REPROVADO_PEDIDO_ORIGEM_NAO_ENCONTRADO]: 'Reprovado: pedido de origem não encontrado',
  [RespostaAutorizadorEnum.REPROVADO_PIN_INCORRETO]: 'Reprovado: PIN incorreto',
  [RespostaAutorizadorEnum.REPROVADO_PRODUTO_NAO_PERMITE_OPERACAO]: 'Reprovado: produto não permite operação',
  [RespostaAutorizadorEnum.REPROVADO_QUANTIDADE_CATEGORIAS_BENEFICIO_INVALIDA]:
    'Reprovado: quantidade de categorias de benefício inválida',
  [RespostaAutorizadorEnum.REPROVADO_SEM_VERIFICACAO_CVC]: 'Reprovado: sem verificação de CVC',
  [RespostaAutorizadorEnum.REPROVADO_TEMPO_EXPIRADO_VISUALIZACAO_CARTAO]:
    'Reprovado: tempo expirado para visualização do cartão',
  [RespostaAutorizadorEnum.REPROVADO_TRANSACAO_INTERNACIONAL_EM_REAL]: 'Reprovado: transação internacional em real',
  [RespostaAutorizadorEnum.REPROVADO_TRANSACAO_JA_CANCELADA]: 'Reprovado: transação já cancelada',
  [RespostaAutorizadorEnum.REPROVADO_TRANSACAO_PARCELADA_NAO_PERMITIDA]: 'Reprovado: transação parcelada não permitida',
  [RespostaAutorizadorEnum.REPROVADO_TRANSMISSAO_DUPLICADA]: 'Reprovado: transmissão duplicada',
  [RespostaAutorizadorEnum.REPROVADO_VALOR_INVALIDO_ESTORNO]: 'Reprovado: valor inválido para estorno',
  [RespostaAutorizadorEnum.REPROVADO_VALOR_INVALIDO_MULTIPLOS_ESTORNOS]:
    'Reprovado: valor inválido para múltiplos estornos',
  [RespostaAutorizadorEnum.REPROVADO_VERIFICACAO_CVC_DESCONHECIDA]: 'Reprovado: verificação de CVC desconhecida',
  [RespostaAutorizadorEnum.REPROVADO_VERIFICACAO_CVC_INVALIDA]: 'Reprovado: verificação de CVC inválida',
  [RespostaAutorizadorEnum.REPROVADO_VERIFICACAO_ICVC]: 'Reprovado: verificação de iCVC',
};

export const subOrigemLabelMap = {
  [SubOrigemEnum.NAO_LIBERADOS]: 'Não liberados',
  [SubOrigemEnum.REPASSES_EXTERIOR]: 'Repasses do exterior',
  [SubOrigemEnum.OUTROS]: 'Outros',
  [SubOrigemEnum.BNDES]: 'BNDES - Banco Nacional de Desenvolvimento Econômico e Social',
  [SubOrigemEnum.FINAME]: 'Finame - Agência Especial de Financiamento Industrial',
  [SubOrigemEnum.FCO]: 'FCO - Fundo Constitucional do Centers Oeste',
  [SubOrigemEnum.FNE]: 'FNE - Fundo Constitucional do Nordeste',
  [SubOrigemEnum.FNO]: 'FNO - Fundo Constitucional do Norte',
  [SubOrigemEnum.FUNDOS_ESTADUAIS_OU_DISTRITAIS]: 'Fundos estaduais ou distritais',
  [SubOrigemEnum.RECURSOS_CAPTADOS_EM_DEPOSITOS_DE_POUPAÇA_PELAS_ENTIDADES_INTEGRANTES_DO_SBPE_DESTINADAS_A_OPERACOES_DE_FINANCIAMENTO_IMOBILIARIO]:
    'Recursos captados em depósitos de poupança pelas entidades integrantes do SBPE destinados à operações de financiamento imobiliário',
  [SubOrigemEnum.FINANCIAMENTOS_CONCEDIDOS_AO_AMPARO_DE_RECURSOS_CONTROLADOS_DO_CREDITO_RURAL]:
    'Financiamentos concedidos ao amparo de recursos controlados do crédito rural',
  [SubOrigemEnum.REPASSES_DE_ORGANISMOS_MULTILATERAIS_NO_EXTERIOR]: 'Repasses de organismos multilaterais no exterior',
  [SubOrigemEnum.OUTROS_REPASSES_EXTERIOR]: 'Outros repasses do exterior',
  [SubOrigemEnum.FUNDOS_OU_PROGRAMAS_ESPECIAIS_DO_GOVERNO_FEDERAL]: 'fundos ou programas especiais do governo federal',
  [SubOrigemEnum.FGTS]: 'FGTS - Fundo de Garantia do Tempo de Serviço',
};

export const subTaxaLabelMap = {
  [SubTaxaEnum.PREFIXADO]: 'Prefixado',
  [SubTaxaEnum.TR_TBF]: 'TR / TBF',
  [SubTaxaEnum.TJLP]: 'TJLP',
  [SubTaxaEnum.LIBOR]: 'Libor',
  [SubTaxaEnum.TLP]: 'TLP',
  [SubTaxaEnum.OUTRAS_TAXAS_POS_FIXADAS]: 'Outras taxas pós-fixadas',
  [SubTaxaEnum.CDI]: 'CDI',
  [SubTaxaEnum.SELIC]: 'Selic',
  [SubTaxaEnum.OUTRAS_TAXAS_FLUTUANTES]: 'Outras taxas flutuantes',
  [SubTaxaEnum.IGPM]: 'IGPM',
  [SubTaxaEnum.IPCA]: 'IPCA',
  [SubTaxaEnum.IPCC]: 'IPCC',
  [SubTaxaEnum.OUTROS_INDICES_PRECO]: 'Outros indices de Preço',
  [SubTaxaEnum.TCR_PRE]: 'TCR - pré',
  [SubTaxaEnum.TCR_POS]: 'TCR - pós',
  [SubTaxaEnum.TRFC_PRE]: 'TRFC - pré',
  [SubTaxaEnum.TRFC_POS]: 'TRFC - pós',
  [SubTaxaEnum.POUPANCA]: 'Poupança',
  [SubTaxaEnum.OUTROS_INDEXADORES]: 'Outros indexadores',
};

export const naturezaTransacaoLabelMap = {
  [NaturezaTransacaoEnum.CREDITO]: 'Crédito',
  [NaturezaTransacaoEnum.DEBITO]: 'Débito',
};

export const naturezaTransacaoColorMap = {
  [NaturezaTransacaoEnum.CREDITO]: ClassTypesEnum.SUCCESS,
  [NaturezaTransacaoEnum.DEBITO]: ClassTypesEnum.DANGER,
};

export const labelOperacaoTransacaoLabelMap = {
  [LabelOperacaoTransacaoEnum.COMPRA]: 'Compra',
  [LabelOperacaoTransacaoEnum.ENCARGOS]: 'Encargos',
  [LabelOperacaoTransacaoEnum.ENCARGOS_SAQUE]: 'Encargos de saque',
  [LabelOperacaoTransacaoEnum.IOF]: 'IOF',
  [LabelOperacaoTransacaoEnum.SAQUE]: 'Saque',
  [LabelOperacaoTransacaoEnum.TARIFA]: 'Tarifa',
};

export const localEntregaCartaoLabelMap = {
  [LocalEntregaCartaoEnum.ATM_SAQUE_PAGUE]: 'ATM Saque e Pague',
  [LocalEntregaCartaoEnum.BACKOFFICE]: 'Backoffice',
  [LocalEntregaCartaoEnum.ENDERECO_ALTERNATIVO]: 'Endereço alternativo',
  [LocalEntregaCartaoEnum.ENDERECO_RESIDENCIAL]: 'Endereço residencial',
};

export const modeloCalculoPagamentoMinimoLabelMap = {
  [ModeloCalculoPagamentoMinimoEnum.PERCENTUAL]: 'Percentual',
  [ModeloCalculoPagamentoMinimoEnum.VALOR_MARGEM_CONSIGNADA]: 'Valor margem consignada',
};

export const origemComercialLabelMap = {
  [OrigemComercialContaCartaoEnum.CARTAO_BENEFICIO]: 'Cartão Benefício',
  [OrigemComercialContaCartaoEnum.FULL_BANKING]: 'Full Banking',
  [OrigemComercialContaCartaoEnum.SUPERCONTA_INSS]: 'SuperConta INSS',
  [OrigemComercialContaCartaoEnum.SUPERCONTA_PUBLICO]: 'SuperConta pública',
  [OrigemComercialContaCartaoEnum.SUPERCONTA_PRIVADO]: 'SuperConta privado',
};

export const periodicidadeLabelMap = {
  [PeriodicidadeEnum.ANUAL]: 'Anual',
  [PeriodicidadeEnum.BIMESTRAL]: 'Bimestral',
  [PeriodicidadeEnum.TRIMESTRAL]: 'Trimestral',
  [PeriodicidadeEnum.UNICO]: 'Unico',
};

export const sexoLabelMap = {
  [SexoEnum.MASCULINO]: 'Masculino',
  [SexoEnum.FEMININO]: 'Feminino',
  [SexoEnum.NAO_INFORMADO]: 'Não informado',
};

export const statusContaCartaoLabelMap = {
  [StatusContaCartaoEnum.ATIVA]: 'Ativa',
  [StatusContaCartaoEnum.ATIVADA]: 'Ativada',
  [StatusContaCartaoEnum.BLOQUEADA]: 'Bloqueada',
  [StatusContaCartaoEnum.CANCELADA]: 'Cancelada',
  [StatusContaCartaoEnum.DORMENTE]: 'Dormente',
  [StatusContaCartaoEnum.FINALIZADA]: 'Finalizada',
  [StatusContaCartaoEnum.INATIVA_30_DIAS]: 'Inativa 30d',
  [StatusContaCartaoEnum.INATIVA_90_DIAS]: 'Inativa 90d',
};

export const statusContaCartaoColorMap = {
  [StatusContaCartaoEnum.ATIVA]: ClassTypesEnum.SUCCESS,
  [StatusContaCartaoEnum.ATIVADA]: ClassTypesEnum.SUCCESS,
  [StatusContaCartaoEnum.BLOQUEADA]: ClassTypesEnum.DANGER,
  [StatusContaCartaoEnum.CANCELADA]: ClassTypesEnum.SECONDARY,
  [StatusContaCartaoEnum.DORMENTE]: ClassTypesEnum.WARNING,
  [StatusContaCartaoEnum.FINALIZADA]: ClassTypesEnum.SECONDARY,
  [StatusContaCartaoEnum.INATIVA_30_DIAS]: ClassTypesEnum.WARNING,
  [StatusContaCartaoEnum.INATIVA_90_DIAS]: ClassTypesEnum.WARNING,
};

export const statusSolicitacaoClienteLabelMap = {
  [StatusSolicitacaoClienteEnum.ABERTO]: 'Aberto',
  [StatusSolicitacaoClienteEnum.AGUARDANDO_DOCUMENTACAO]: 'Aguardando Documentação',
  [StatusSolicitacaoClienteEnum.DEFERIDO]: 'Deferido',
  [StatusSolicitacaoClienteEnum.EM_ANDAMENTO]: 'Em andamento',
  [StatusSolicitacaoClienteEnum.INDEFERIDO]: 'Indeferido',
  [StatusSolicitacaoClienteEnum.REABERTO]: 'Reaberto',
  [StatusSolicitacaoClienteEnum.REQUER_INFORMACOES]: 'Requer Informações',
};

export const statusSolicitacaoClienteColorMap = {
  [StatusSolicitacaoClienteEnum.ABERTO]: ClassTypesEnum.DANGER,
  [StatusSolicitacaoClienteEnum.AGUARDANDO_DOCUMENTACAO]: ClassTypesEnum.WARNING,
  [StatusSolicitacaoClienteEnum.DEFERIDO]: ClassTypesEnum.SUCCESS,
  [StatusSolicitacaoClienteEnum.EM_ANDAMENTO]: ClassTypesEnum.WARNING,
  [StatusSolicitacaoClienteEnum.INDEFERIDO]: ClassTypesEnum.SUCCESS,
  [StatusSolicitacaoClienteEnum.REABERTO]: ClassTypesEnum.DANGER,
  [StatusSolicitacaoClienteEnum.REQUER_INFORMACOES]: ClassTypesEnum.WARNING,
};

export const statusProcessamentoLabelMap = {
  [StatusProcessamentoEnum.EM_PROCESSAMENTO]: 'Em processamento',
  [StatusProcessamentoEnum.SUCESSO]: 'Sucesso',
  [StatusProcessamentoEnum.ERRO]: 'Erro',
};

export const statusProcessamentoColorMap = {
  [StatusProcessamentoEnum.EM_PROCESSAMENTO]: ClassTypesEnum.WARNING,
  [StatusProcessamentoEnum.SUCESSO]: ClassTypesEnum.SUCCESS,
  [StatusProcessamentoEnum.ERRO]: ClassTypesEnum.DANGER,
};

export const statusSolicitacaoParcelamentoLabelMap = {
  [StatusSolicitacaoParcelamentoEnum.CANCELADA]: 'Cancelada',
  [StatusSolicitacaoParcelamentoEnum.CANCELADA_POR_DIFERENCA_PAGAMENTO]: 'Cancelada por diferença de pagamento',
  [StatusSolicitacaoParcelamentoEnum.FINALIZADA]: 'Finalizada',
  [StatusSolicitacaoParcelamentoEnum.PENDENTE]: 'Pendente',
};

export const tipoCnabLabelMap = {
  [TipoCnabEnum.BRADESCO]: 'Bradesco',
  [TipoCnabEnum.SANTANDER]: 'Santander',
};

export const subtipoCnabLabelMap = {
  [SubtipoCnabEnum.CNAB_240]: 'CNAB 240',
  [SubtipoCnabEnum.CNAB_400]: 'CNAB 400',
};

export const tipoArredondamentoDoacaoLabelMap = {
  [TipoArredondamentoDoacaoEnum.CENTENA]: 'Centena',
  [TipoArredondamentoDoacaoEnum.DEZENA]: 'Dezena',
  [TipoArredondamentoDoacaoEnum.UNIDADE]: 'Unidade',
};

export const tipoCartaoLabelMap = {
  [TipoCartaoEnum.FISICO]: 'Físico',
  [TipoCartaoEnum.VIRTUAL]: 'Virtual',
};

export const tipoContratoTermosUsoLabelMap = {
  [TipoContratosTermosUsoEnum.TERMOS_USO_APP]: 'Termos de uso do App',
  [TipoContratosTermosUsoEnum.TERMOS_USO_CARTAO_CREDITO]: 'Termos de uso do cartão de crédito',
};

export const tipoEnderecoLabelMap = {
  [TipoEnderecoEnum.COMERCIAL]: 'Comercial',
  [TipoEnderecoEnum.ENTREGA_CARTAO]: 'Entrega do Cartão',
  [TipoEnderecoEnum.RESIDENCIAL]: 'Residencial',
};

export const tipoProcessamentoCalendarioCorteLabelMap = {
  [TipoProcessamentoCalendarioCorteEnum.DIA_CORRIDO]: 'Dia corrido',
  [TipoProcessamentoCalendarioCorteEnum.DIA_UTIL]: 'Dia util',
};

export const tipoProtocoloProcessamentoLoteLabelMap = {
  [TipoProtocoloProcessamentoLoteEnum.GERACAO_ARQUIVO]: 'Geração de arquivo',
  [TipoProtocoloProcessamentoLoteEnum.IMPORTACAO_ARQUIVO]: 'Importação de arquivo',
  [TipoProtocoloProcessamentoLoteEnum.ROTINA_PERIODICA]: 'Rotina periódica',
  [TipoProtocoloProcessamentoLoteEnum.RELATORIO_OPERACAO]: 'Relatório de operação',
};

export const contextoProtocoloProcessamentoLoteLabelMap = {
  [ContextoProtocoloProcessamentoLoteEnum.ABU_R274]: 'ABU R274',
  [ContextoProtocoloProcessamentoLoteEnum.ARQUIVO_5186]: 'Arquivo 5186',
  [ContextoProtocoloProcessamentoLoteEnum.ARQUIVO_6308]: 'Arquivo 6308',
  [ContextoProtocoloProcessamentoLoteEnum.ARQUIVO_6308_CONCEMIS]: 'Arquivo 6308 Concemis',
  [ContextoProtocoloProcessamentoLoteEnum.ARQUIVO_6308_CONTATOS]: 'Arquivo 6308 Contatos',
  [ContextoProtocoloProcessamentoLoteEnum.ARQUIVO_6308_DATABASE]: 'Arquivo 6308 Database',
  [ContextoProtocoloProcessamentoLoteEnum.ARQUIVO_6308_EMISSOR]: 'Arquivo 6308 Emissor',
  [ContextoProtocoloProcessamentoLoteEnum.ARQUIVO_6308_LUCREMIS]: 'Arquivo 6308 Lucremis',
  [ContextoProtocoloProcessamentoLoteEnum.ARQUIVO_6308_PORTADOR]: 'Arquivo 6308 Portador',
  [ContextoProtocoloProcessamentoLoteEnum.ARQUIVO_APRESENTACAO_GOODCARD]: 'Arquivo Apresentação Goodcard',
  [ContextoProtocoloProcessamentoLoteEnum.ARQUIVO_ATUALIZACAO_DE_SALDO]: 'Arquivo de Atualização de Saldo',
  [ContextoProtocoloProcessamentoLoteEnum.ARQUIVO_CADASTRAL]: 'Arquivo Cadastral',
  [ContextoProtocoloProcessamentoLoteEnum.ARQUIVO_CADASTRAL_ALTERACOES_CADASTRAIS]:
    'Arquivo Cadastral de Alterações Cadastrais',
  [ContextoProtocoloProcessamentoLoteEnum.ARQUIVO_CADASTRAL_BATIMENTOS_BLOQUEIOS_CONTA]:
    'Arquivo Cadastral de Batimentos de Bloqueios de Conta',
  [ContextoProtocoloProcessamentoLoteEnum.ARQUIVO_CADASTRAL_BATIMENTOS_CARTOES]:
    'Arquivo Cadastral de Batimentos de Cartões',
  [ContextoProtocoloProcessamentoLoteEnum.ARQUIVO_CADASTRAL_BATIMENTOS_CLIENTES]:
    'Arquivo Cadastral de Batimentos de Clientes',
  [ContextoProtocoloProcessamentoLoteEnum.ARQUIVO_CADASTRAL_BATIMENTOS_CONTAS_CARTAO]:
    'Arquivo Cadastral de Batimentos de Contas Cartão',
  [ContextoProtocoloProcessamentoLoteEnum.ARQUIVO_CADASTRO_POSITIVO]: 'Arquivo Cadastro Positivo',
  [ContextoProtocoloProcessamentoLoteEnum.ARQUIVO_CADOC_3026]: 'Arquivo Cadoc 3026',
  [ContextoProtocoloProcessamentoLoteEnum.ARQUIVO_CADOC_3040]: 'Arquivo Cadoc 3040',
  [ContextoProtocoloProcessamentoLoteEnum.ARQUIVO_CADOC_3040_COMPLEMENTAR]: 'Arquivo Cadoc 3040 Complementar',
  [ContextoProtocoloProcessamentoLoteEnum.ARQUIVO_CADOC_3050]: 'Arquivo Cadoc 3050',
  [ContextoProtocoloProcessamentoLoteEnum.ARQUIVO_CAF_501]: 'Arquivo Caf 501',
  [ContextoProtocoloProcessamentoLoteEnum.ARQUIVO_CHARGEBACK]: 'Arquivo Chargeback',
  [ContextoProtocoloProcessamentoLoteEnum.ARQUIVO_CLEARING]: 'Arquivo Clearing',
  [ContextoProtocoloProcessamentoLoteEnum.ARQUIVO_CNAB_DE_REMESSA]: 'Arquivo Cnab de Remessa',
  [ContextoProtocoloProcessamentoLoteEnum.ARQUIVO_CNAB_DE_RETORNO]: 'Arquivo Cnab de Retorno',
  [ContextoProtocoloProcessamentoLoteEnum.ARQUIVO_CONTABIL]: 'Arquivo Contábil',
  [ContextoProtocoloProcessamentoLoteEnum.ARQUIVO_CONTABIL_ANALITICO_DIARIO]: 'Arquivo Contábil Analítico Diário',
  [ContextoProtocoloProcessamentoLoteEnum.ARQUIVO_CONTABIL_SUMARIZADO]: 'Arquivo Contábil Sumarizado',
  [ContextoProtocoloProcessamentoLoteEnum.ARQUIVO_DECRED]: 'Arquivo Decred',
  [ContextoProtocoloProcessamentoLoteEnum.ARQUIVO_DIMP]: 'Arquivo Dimp',
  [ContextoProtocoloProcessamentoLoteEnum.ARQUIVO_EMBOSSING]: 'Arquivo de Embossing',
  [ContextoProtocoloProcessamentoLoteEnum.ARQUIVO_FAIXAS_MCC]: 'Arquivo Faixas MCC',
  [ContextoProtocoloProcessamentoLoteEnum.ARQUIVO_FATURAS]: 'Arquivo de Faturas',
  [ContextoProtocoloProcessamentoLoteEnum.ARQUIVO_IOF_ANALITICO]: 'Arquivo IOF Analitico',
  [ContextoProtocoloProcessamentoLoteEnum.ARQUIVO_IOF_ESTORNO]: 'Arquivo IOF Estorno',
  [ContextoProtocoloProcessamentoLoteEnum.ARQUIVO_IOF_SINTETICO_DIARIO]: 'Arquivo IOF Sintetico Diario',
  [ContextoProtocoloProcessamentoLoteEnum.ARQUIVO_PREVIAS]: 'Arquivo de Prévias',
  [ContextoProtocoloProcessamentoLoteEnum.ARQUIVO_SALDO_ROTATIVO]: 'Arquivo de Saldo Rotativo',
  [ContextoProtocoloProcessamentoLoteEnum.ARQUIVO_TRANSACAO_LOJISTA_SUMARIZADO]: 'Arquivo Transação Lojista Sumarizado',
  [ContextoProtocoloProcessamentoLoteEnum.ARQUIVO_TRANSACAO_PORTADOR_ANALITICO]: 'Arquivo Transação Portador Analítico',
  [ContextoProtocoloProcessamentoLoteEnum.CADASTRAR_TAXAS_CAMBIO]: 'Cadastrar Taxas de Câmbio',
  [ContextoProtocoloProcessamentoLoteEnum.CADASTRAR_TAXA_SELIC]: 'Cadastrar Taxa Selic',
  [ContextoProtocoloProcessamentoLoteEnum.CALCULAR_DESCONTO_FOLHA_CONSIGNADO]: 'Calcular Desconto Em Folha Consignado',
  [ContextoProtocoloProcessamentoLoteEnum.CALENDARIO_DE_CORTE]: 'Calendário de Corte',
  [ContextoProtocoloProcessamentoLoteEnum.CANCELAR_TRANSACOES_PENDENTES]: 'Cancelar Transações Pendente',
  [ContextoProtocoloProcessamentoLoteEnum.CARTA_SENHA]: 'Carta Senha',
  [ContextoProtocoloProcessamentoLoteEnum.ENVIAR_PUSH_NOTIFICATION_BLOQUEIO_CARTAO]:
    'Enviar Push Notifications Bloqueio Cartão',
  [ContextoProtocoloProcessamentoLoteEnum.ENVIAR_PUSH_NOTIFICATION_BLOQUEIO_CONTA_CARTAO]:
    'Enviar Push Notifications Bloqueio Conta Cartão',
  [ContextoProtocoloProcessamentoLoteEnum.ENVIAR_PUSH_NOTIFICATION_FECHAMENTO_FATURA]:
    'Enviar Push Notifications Fechamento Fatura',
  [ContextoProtocoloProcessamentoLoteEnum.ENVIAR_PUSH_NOTIFICATION_LEMBRETE_DOACAO_RECORRENTE_MENSAL]:
    'Enviar Push Notifications Lembrete Doação Recorrente Mensal',
  [ContextoProtocoloProcessamentoLoteEnum.ENVIAR_PUSH_NOTIFICATION_LEMBRETE_FATURA]:
    'Enviar Push Notifications Lembrete Fatura',
  [ContextoProtocoloProcessamentoLoteEnum.FATURAR_TRANSACOES_PERIODO_ABERTO]: 'Faturar Transações de Período Aberto',
  [ContextoProtocoloProcessamentoLoteEnum.FECHAR_FATURAS]: 'Fechar Faturas',
  [ContextoProtocoloProcessamentoLoteEnum.GERAR_CALENDARIOS_DE_CORTE]: 'Gerar Calendários de Corte',
  [ContextoProtocoloProcessamentoLoteEnum.GERAR_HISTORICOS_CONTAS_PROCESSADORA]: 'Gerar Históricos Contas Processadora',
  [ContextoProtocoloProcessamentoLoteEnum.GERAR_PDF_FATURAS]: 'Gerar PDF Faturas',
  [ContextoProtocoloProcessamentoLoteEnum.PROCESSAR_CLASSIFICACAO_CONTABIL]: 'Processar Classificação Contábil',
  [ContextoProtocoloProcessamentoLoteEnum.PROCESSAR_PAGAMENTOS_MINIMOS]: 'Processar Pagamentos Mínimos',
  [ContextoProtocoloProcessamentoLoteEnum.PROCESSAR_SOLICITACOES_DE_ABERTURA_DE_CONTA]:
    'Processar Solicitações de Abertura de Conta',
  [ContextoProtocoloProcessamentoLoteEnum.REGISTRAR_BOLETOS]: 'Registrar Boletos',
  [ContextoProtocoloProcessamentoLoteEnum.REGISTRAR_PAGAMENTO_BOLETOS]: 'Registrar Pagamento Boletos',
  [ContextoProtocoloProcessamentoLoteEnum.REGISTRAR_SALDO_DIA_CONTA_CONTABIL]: 'Registrar Saldo Dia Conta Contábil',
  [ContextoProtocoloProcessamentoLoteEnum.RELATORIO_OPERACAO_AUTORIZACOES]: 'Relatório de Operação de Autorizações',
  [ContextoProtocoloProcessamentoLoteEnum.VALIDAR_PARAMETRIZACAO]: 'Validar Parametrização',
};

export const tipoRoteiroContabilLabelMap = {
  [TipoRoteiroContabilEnum.PAGAMENTO]: 'Pagamento',
  [TipoRoteiroContabilEnum.TRANSACAO_CONFIRMADA]: 'Transação confirmada',
  [TipoRoteiroContabilEnum.ESTORNO]: 'Estorno',
  [TipoRoteiroContabilEnum.TRANSFERENCIA_ROTATIVO]: 'Transferência rotativo',
  [TipoRoteiroContabilEnum.CONTESTACAO]: 'Contestação',
  [TipoRoteiroContabilEnum.PARCELAMENTO_REFINANCIAMENTO]: 'Parcelamento refinanciamento',
  [TipoRoteiroContabilEnum.JUROS]: 'Juros',
  [TipoRoteiroContabilEnum.APROPRIACAO_DE_JUROS]: 'Apropriação de juros',
  [TipoRoteiroContabilEnum.VENCIMENTO]: 'Vencimento',
};

export const tipoEventosContabeisLabelMap = {
  [TipoEventoContabilEnum.SALDO_FATURADO]: 'Saldo faturado',
  [TipoEventoContabilEnum.SALDO_ROTATIVO]: 'Saldo rotativo',
  [TipoEventoContabilEnum.PROJECAO_IOF_DIARIO_TOTAL]: 'Projeção IOF diário total',
  [TipoEventoContabilEnum.MULTA_VENCIMENTO]: 'Multa vencimento',
  [TipoEventoContabilEnum.ENCARGOS_SOBRE_SALDO_ROTATIVO]: 'Encargos sobre saldo rotativo',
  [TipoEventoContabilEnum.APROPRIACAO_DIARIA_IOF_DIARIO]: 'Apropriação diária IOF diário',
  [TipoEventoContabilEnum.CONTA_TRANSITORIA_CRELI]: 'Conta transitória Creli',
  [TipoEventoContabilEnum.PAGAMENTO_COMPRA_CREDITO_A_VISTA]: 'Pagamento compra à vista',
  [TipoEventoContabilEnum.PAGAMENTO_PARCELAMENTO_REFINANCIAMENTO]: 'Pagamento parcelamento refinanciamento',
  [TipoEventoContabilEnum.PAGAMENTO_ROTATIVO]: 'Pagamento rotativo',
  [TipoEventoContabilEnum.PAGAMENTO_ROTATIVO_ENCARGOS]: 'Pagamento rotativo encargos',
  [TipoEventoContabilEnum.PAGAMENTO_MORA_ROTATIVO]: 'Pagamento mora rotativo',
  [TipoEventoContabilEnum.PAGAMENTO_IOF_SOBRE_OPERAÇÃO_DE_ROTATIVO]: 'Pagamento IOF sobre operação de rotativo',
  [TipoEventoContabilEnum.PAGAMENTO_MULTA_POR_ATRASO_ROTATIVO]: 'Pagamento multa por atraso rotativo',
  [TipoEventoContabilEnum.PAGAMENTO_IOF_SOBRE_ENCARGOS_DO_ROTATIVO]: 'Pagamento IOF sobre encargos do rotativo',
  [TipoEventoContabilEnum.PAGAMENTO_ENCARGOS_PARCELAMENTO_REFINANCIAMENTO]:
    'Pagamento encargos parcelamento refinanciamento',
  [TipoEventoContabilEnum.PAGAMENTO_IOF_SOBRE_ENCARGOS_DO_PARCELAMENTO]: 'Pagamento IOF sobre encargos do parcelamento',
  [TipoEventoContabilEnum.PAGAMENTO_TARIFAS]: 'Pagamento tarifas',
  [TipoEventoContabilEnum.PAGAMENTO_SAQUE]: 'Pagamento saque',
  [TipoEventoContabilEnum.PAGAMENTO_IOF_SOBRE_SAQUE]: 'Pagamento iof sobre saque',
  [TipoEventoContabilEnum.PAGAMENTO_SAQUE_ENCARGOS]: 'Pagamento saque encargos',
  [TipoEventoContabilEnum.PAGAMENTO_PARCELADO_LOJISTA_SEM_JUROS]: 'Pagamento parcelado lojista sem juros',
  [TipoEventoContabilEnum.ENCARGO_ROTATIVO_DIARIO]: 'Encargo rotativo diário',
  [TipoEventoContabilEnum.CREDITO_PARCELAMENTO_FATURA]: 'Credito parcelamento fatura',
  [TipoEventoContabilEnum.ENCARGO_PARCELAMENTO_FATURA]: 'Encargo parcelamento fatura',
  [TipoEventoContabilEnum.ENCARGOS_SOBRE_PARCELADO_EMISSOR]: 'Encargos sobre parcelado emissor',
  [TipoEventoContabilEnum.ESTORNO_TARIFA_SAQUE_CREDITO_PESSOA_JURIDICA]: 'Estorno tarifa saque crédito PJ',
  [TipoEventoContabilEnum.ESTORNO_TARIFA_SAQUE_CREDITO_PESSOA_FISICA]: 'Estorno tarifa saque crédito PF',
  [TipoEventoContabilEnum.ESTORNO_TARIFA_NOVA_VIA_CARTAO]: 'Estorno tarifa nova via cartão',
  [TipoEventoContabilEnum.CONTESTACAO_FRAUDE]: 'Contestação fraude',
  [TipoEventoContabilEnum.IOF_PARCELADO_EMISSOR]: 'IOF parcelado emissor',
  [TipoEventoContabilEnum.IOF_SOBRE_ENCARGOS_PARCELADO_EMISSOR]: 'IOF sobre encargos parcelado emissor',
  [TipoEventoContabilEnum.PAGAMENTO_IOF_PARCELADO_EMISSOR]: 'Pagamento IOF parcelado emissor',
  [TipoEventoContabilEnum.APROPRIACAO_DE_JUROS]: 'Apropriação de juros',
  [TipoEventoContabilEnum.CANCELAMENTO_COMPRA]: 'Cancelamento compra',
  [TipoEventoContabilEnum.ESTORNO_PAGAMENTO_SAQUE]: 'Estorno pagamento saque',
  [TipoEventoContabilEnum.ESTORNO_PAGAMENTO_SAQUE_ENCARGOS]: 'Estorno pagamento saque encargos',
  [TipoEventoContabilEnum.ESTORNO_TRANSFERENCIA_PAGAMENTO_CONTAS_CREDITO_PARA_ROTATIVO]:
    'Estorno transferência pagamento contas crédito para rotativo',
  [TipoEventoContabilEnum.ESTORNO_CONTESTACAO_PREJUIZO]: 'Estorno contestação prejuízo',
  [TipoEventoContabilEnum.ESTORNO_COMPRA_CREDITO_A_VISTA]: 'Estorno compra a vista',
  [TipoEventoContabilEnum.ESTORNO_ENCARGOS_PARCELAMENTO]: 'Estorno encargos parcelamento',
  [TipoEventoContabilEnum.ESTORNO_CREDITO_PARCELAMENTO_EMISSOR]: 'Estorno crédito parcelamento emissor',
  [TipoEventoContabilEnum.ESTORNO_CREDITO_ENCARGOS_PARCELADO_EMISSOR]: 'Estorno crédito encargos parcelado emissor',
  [TipoEventoContabilEnum.ESTORNO_CREDITO_PARCELAMENTO_ROTATIVO]: 'Estorno crédito parcelamento rotativo',
  [TipoEventoContabilEnum.ESTORNO_PAGAMENTO_ENCARGOS_ROTATIVO]: 'Estorno pagamento encargos rotativo',
  [TipoEventoContabilEnum.ESTORNO_PAGAMENTO_COMPRA_CREDITO_A_VISTA]: 'Estorno pagemento compra a vista',
  [TipoEventoContabilEnum.ESTORNO_PAGAMENTO_PARCELADO_LOJISTA_SEM_JUROS]: 'Estorno pagamento parcelado',
  [TipoEventoContabilEnum.REDUCAO_LIMITE_CREDITO]: 'Redução limite crédito',
  [TipoEventoContabilEnum.REDUCAO_LIMITE_CREDITO_DISPONIVEL]: 'Redução limite crédito disponível',
  [TipoEventoContabilEnum.AUMENTO_LIMITE_CREDITO_DISPONIVEL]: 'Aumento limite crédito disponìvel',
  [TipoEventoContabilEnum.AUMENTO_LIMITE_CREDITO]: 'Aumento limite crédito',
  [TipoEventoContabilEnum.ABERTURA_LIMITE_CREDITO]: 'Abertura limite crédito',
  [TipoEventoContabilEnum.PAGAMENTO_ENCARGOS_SOBRE_PARCELADO_EMISSOR]: 'Pagamento encargos sobre parcelado emissor',
};

export const tipoContaBancariaLabelMap = {
  [TipoContaBancariaEnum.CONTA_FACIL]: 'Conta fácil',
  [TipoContaBancariaEnum.CONTA_PAGAMENTO]: 'Conta pagamento',
  [TipoContaBancariaEnum.CORRENTE]: 'Conta corrente',
  [TipoContaBancariaEnum.POUPANCA]: 'Conta poupança',
};

export const tipoFeriadoLabelMap = {
  [TipoFeriadoEnum.ESTADUAL]: 'Estadual',
  [TipoFeriadoEnum.FEDERAL]: 'Federal',
  [TipoFeriadoEnum.MUNICIPAL]: 'Municipal',
};

export const tipoBloqueioContaCartaoLabelMap = {
  [TipoBloqueioContaCartaoEnum.EM_ATRASO]: 'Em atraso',
  [TipoBloqueioContaCartaoEnum.PRE_CRELI]: 'Pré-creli',
  [TipoBloqueioContaCartaoEnum.CRELI]: 'Creli',
  [TipoBloqueioContaCartaoEnum.PREJUIZO]: 'Prejuízo',
  [TipoBloqueioContaCartaoEnum.EXCESSO_LIMITE]: 'Excesso limite',
  [TipoBloqueioContaCartaoEnum.CONTA_CANCELADA]: 'Conta cancelada',
};

export const tipoBloqueioCartaoLabelMap = {
  [TipoBloqueioCartaoEnum.SENHA_INCORRETA]: 'Senha incorreta',
  [TipoBloqueioCartaoEnum.VENCIMENTO_CARTAO]: 'Vencimento',
  [TipoBloqueioCartaoEnum.ATIVACAO_CARTAO]: 'Ativação cartão',
  [TipoBloqueioCartaoEnum.BLOQUEIO_USUARIO]: 'Bloqueio usuário',
  [TipoBloqueioCartaoEnum.CANCELAMENTO_ROUBO]: 'Cancelamento por roubo',
  [TipoBloqueioCartaoEnum.CANCELAMENTO_PERDA]: 'Cancelamento por perda',
  [TipoBloqueioCartaoEnum.CANCELAMENTO_DANIFICADO]: 'Cancelamento por danificação',
  [TipoBloqueioCartaoEnum.CANCELAMENTO_CLIENTE]: 'Cancelamento cliente',
  [TipoBloqueioCartaoEnum.CANCELAMENTO_ECOMMERCE]: 'Cancelamento e-commerce',
  [TipoBloqueioCartaoEnum.CVC_INCORRETO]: 'CVC incorreto',
};

export const tipoTransacaoLabelMap = {
  [OperacaoTransacaoEnum.COMPRA_CREDITO_A_VISTA]: 'Compra à vista',
  [OperacaoTransacaoEnum.SAQUE_AUTORIZADO_CREDITO_A_VISTA]: 'Saque autorizado à vista',
  [OperacaoTransacaoEnum.PAGAMENTO_FATURA]: 'Pagamento da fatura',
  [OperacaoTransacaoEnum.COMPRA_CREDITO_PARCELADA]: 'Compra parcelada',
  [OperacaoTransacaoEnum.TARIFA_SAQUE_AUTORIZADO_CREDITO]: 'Tarifa de saque autorizado',
  [OperacaoTransacaoEnum.IOF_ADICIONAL_SAQUE_AUTORIZADO_CREDITO_A_VISTA]: 'IOF adicional de saque autorizado à vista',
  [OperacaoTransacaoEnum.IOF_SAQUE_AUTORIZADO_CREDITO_A_VISTA]: 'IOF saque autorizado à vista',
  [OperacaoTransacaoEnum.JUROS_SAQUE_AUTORIZADO_CREDITO_A_VISTA]: 'Juros saque autorizado à vista',
  [OperacaoTransacaoEnum.IOF_ADICIONAL_ROTATIVO]: 'IOF adicional rotativo',
  [OperacaoTransacaoEnum.IOF_ROTATIVO]: 'IOF rotativo',
  [OperacaoTransacaoEnum.JUROS_ROTATIVO]: 'Juros rotativo',
  [OperacaoTransacaoEnum.COMPRA_CREDITO_A_VISTA_INTERNACIONAL]: 'Compra à vista internacional',
  [OperacaoTransacaoEnum.SAQUE_COMPLEMENTAR_CREDITO_A_VISTA]: 'Saque complementar à vista',
  [OperacaoTransacaoEnum.IOF_ADICIONAL_SAQUE_COMPLEMENTAR_CREDITO_A_VISTA]: 'IOF adicional saque complementar à vista',
  [OperacaoTransacaoEnum.IOF_SAQUE_COMPLEMENTAR_CREDITO_A_VISTA]: 'IOF saque complementar à vista',
  [OperacaoTransacaoEnum.JUROS_SAQUE_COMPLEMENTAR_CREDITO_A_VISTA]: 'Juros saque complementar à vista',
  [OperacaoTransacaoEnum.TARIFA_SAQUE_COMPLEMENTAR_CREDITO]: 'Tarifa saque complementar',
  [OperacaoTransacaoEnum.SAQUE_AUTORIZADO_CREDITO_PARCELADO]: 'Saque parcelado autorizado',
  [OperacaoTransacaoEnum.SAQUE_COMPLEMENTAR_CREDITO_PARCELADO]: 'Saque parcelado complementar',
  [OperacaoTransacaoEnum.IOF_COMPRA_CREDITO_A_VISTA_INTERNACIONAL]: 'IOF compra internacional',
  [OperacaoTransacaoEnum.TARIFA_PRIMEIRO_USO_CARTAO]: 'Tarifa de primeiro uso',
  [OperacaoTransacaoEnum.MULTA_ATRASO]: 'Multa por atraso',
  [OperacaoTransacaoEnum.ESTORNO_COMPRA_CREDITO_A_VISTA]: 'Estorno compra à vista',
  [OperacaoTransacaoEnum.ESTORNO_COMPRA_CREDITO_PARCELADA]: 'Estorno compra parcelada',
  [OperacaoTransacaoEnum.ESTORNO_IOF_COMPRA_CREDITO_A_VISTA_INTERNACIONAL]: 'Estorno IOF compra internacional',
  [OperacaoTransacaoEnum.ESTORNO_IOF_ADICIONAL_SAQUE_AUTORIZADO_CREDITO_A_VISTA]:
    'Estorno IOF adicional saque autorizado à vista',
  [OperacaoTransacaoEnum.ESTORNO_IOF_SAQUE_AUTORIZADO_CREDITO_A_VISTA]: 'Estorno IOF saque autorizado à vista',
  [OperacaoTransacaoEnum.ESTORNO_IOF_ADICIONAL_ROTATIVO]: 'Estorno IOF adicional rotativo',
  [OperacaoTransacaoEnum.ESTORNO_IOF_ROTATIVO]: 'Estorno IOF rotativo',
  [OperacaoTransacaoEnum.ESTORNO_IOF_ADICIONAL_SAQUE_COMPLEMENTAR_CREDITO_A_VISTA]:
    'Estorno IOF adicional saque complementar à vista',
  [OperacaoTransacaoEnum.ESTORNO_IOF_SAQUE_COMPLEMENTAR_CREDITO_A_VISTA]: 'Estorno IOF saque complementar à vista',
  [OperacaoTransacaoEnum.ESTORNO_COMPRA_CREDITO_A_VISTA_INTERNACIONAL]: 'Estorno compra à vista internacional',
  [OperacaoTransacaoEnum.COMPRA_CREDITO_PARCELADA_EMISSOR]: 'Compra parcelada emissor',
  [OperacaoTransacaoEnum.TAXA_COMPRA_CREDITO_PARCELADA_EMISSOR]: 'Taxa compra parcelada emissor',
  [OperacaoTransacaoEnum.SAQUE_CREDITO]: 'Saque de crédito',
  [OperacaoTransacaoEnum.PAGAMENTO_ATM]: 'Pagamento ATM',
  [OperacaoTransacaoEnum.COMPRA_CREDITO_COM_CASHBACK]: 'Compra com cashback',
  [OperacaoTransacaoEnum.DESEMBOLSO]: 'Desembolso',
  [OperacaoTransacaoEnum.ESTORNO_JUROS_ROTATIVO]: 'Estorno juros rotativo',
  [OperacaoTransacaoEnum.JUROS_MORA]: 'Juros mora',
  [OperacaoTransacaoEnum.PARCELAMENTO_FATURA]: 'Parcelamento de fatura',
  [OperacaoTransacaoEnum.DOACAO_ESPONTANEA]: 'Doação espontânea',
  [OperacaoTransacaoEnum.DOACAO_ARREDONDAMENTO_COMPRA]: 'Doação arrededondamento da compra',
  [OperacaoTransacaoEnum.DOACAO_ARREDONDAMENTO_FATURA]: 'Doação arredondamento da fatura',
  [OperacaoTransacaoEnum.TARIFA_ANUIDADE]: 'Tarifa anuidade',
  [OperacaoTransacaoEnum.DESCONTO_ANTECIPACAO_PARCELA]: 'Desconto por atencipação de parcela',
  [OperacaoTransacaoEnum.TARIFA_SAQUE_CREDITO]: 'Tarifa saque crédito PF',
  [OperacaoTransacaoEnum.ESTORNO_TARIFA_SAQUE_CREDITO]: 'Estorno tarifa saque crédito',
  [OperacaoTransacaoEnum.IOF_SAQUE_CREDITO]: 'IOF saque crédito',
  [OperacaoTransacaoEnum.TARIFA_NOVA_VIA_CARTAO]: 'Tarifa nova via cartão',
  [OperacaoTransacaoEnum.ESTORNO_TARIFA_NOVA_VIA_CARTAO]: 'Estorno tarifa nova via cartão',
  [OperacaoTransacaoEnum.ESTORNO_SAQUE_CREDITO]: 'Estorno saque crédito',
  [OperacaoTransacaoEnum.ESTORNO_IOF_SAQUE_CREDITO]: 'Estorno IOF saque créfito',
  [OperacaoTransacaoEnum.TARIFA_SAQUE_CREDITO_PJ]: 'Tarifa saque crédito PJ',
  [OperacaoTransacaoEnum.TARIFA_AVALIACAO_AUMENTO_LIMITE_EMERGENCIAL]: 'Tarifa avaliação aumento limite emergencial',
  [OperacaoTransacaoEnum.ESTORNO_TARIFA_SAQUE_CREDITO_PJ]: 'Estorno tarifa saque crédito PJ',
  [OperacaoTransacaoEnum.ESTORNO_TARIFA_AVALIACAO_AUMENTO_LIMITE_EMERGENCIAL]:
    'Estorno tarifa avaliação aumento limite emergencial',
  [OperacaoTransacaoEnum.JUROS_SAQUE_CREDITO]: 'Juros saque crédito',
  [OperacaoTransacaoEnum.IOF_ADICIONAL_SAQUE_CREDITO]: 'IOF adicional saque crédito',
  [OperacaoTransacaoEnum.IOF_ADICIONAL_PARCELAMENTO_FATURA]: 'IOF adicional parcelamento fatura',
  [OperacaoTransacaoEnum.IOF_PARCELAMENTO_FATURA]: 'IOF parcelamento fatura',
  [OperacaoTransacaoEnum.IOF_ADICIONAL_COMPRA_CREDITO_PARCELADA_EMISSOR]: 'IOF adicional compra parcelada emissor',
  [OperacaoTransacaoEnum.IOF_COMPRA_CREDITO_PARCELADA_EMISSOR]: 'IOF compra parcelada emissor',
  [OperacaoTransacaoEnum.ESTORNO_JUROS_SAQUE_CREDITO]: 'Estorno juros saque crédito',
  [OperacaoTransacaoEnum.ESTORNO_IOF_ADICIONAL_SAQUE_CREDITO]: 'Estorno IOF adicional saque crédito',
  [OperacaoTransacaoEnum.DOACAO_RECORRENTE_MENSAL]: 'Doação recorrente mensal',
  [OperacaoTransacaoEnum.ESTORNO_DOACAO]: 'Estorno doação',
  [OperacaoTransacaoEnum.AJUSTE_FINANCEIRO_CREDITO]: 'Ajuste financeiro de crédito',
  [OperacaoTransacaoEnum.AJUSTE_FINANCEIRO_DEBITO]: 'Ajuste financeiro de débito',
  [OperacaoTransacaoEnum.ESTORNO_MULTA_ATRASO]: 'Estorno multa atraso',
  [OperacaoTransacaoEnum.COMPRA_CREDITO_PARCELADA_LIMITE_PARCIAL]: 'Compra parcelada limite parcial',
  [OperacaoTransacaoEnum.RESTABELECIMENTO_LIMITE_CONSIGNADO]: 'Restabelecimento limite consignado',
  [OperacaoTransacaoEnum.RECARGA_CREDITO]: 'Recarga de crédito',
  [OperacaoTransacaoEnum.CONSULTA_SALDO]: 'Consulta de saldo',
};

export const tipoTarifaLabelMap = {
  [TipoTarifaEnum.TARIFA_SAQUE_AUTORIZADO_CREDITO]: ' Saque autorizado',
  [TipoTarifaEnum.TARIFA_SAQUE_COMPLEMENTAR_CREDITO]: 'Saque complementar',
  [TipoTarifaEnum.TARIFA_PRIMEIRO_USO_CARTAO]: 'Primeiro uso',
  [TipoTarifaEnum.TARIFA_EMISSAO_CARTAO]: 'Emissão de cartão',
  [TipoTarifaEnum.TARIFA_ANUIDADE]: 'Anuidade',
  [TipoTarifaEnum.TARIFA_SAQUE_CREDITO]: 'Saque de crédito PF',
  [TipoTarifaEnum.TARIFA_NOVA_VIA_CARTAO]: 'Nova via cartão',
  [TipoTarifaEnum.TARIFA_SAQUE_CREDITO_PJ]: 'Saque de crédito PJ',
  [TipoTarifaEnum.TARIFA_AVALIACAO_AUMENTO_LIMITE_EMERGENCIAL]: 'Avaliação aumento limite emergencial',
};

export const tipoTitularidadeCartaoLabelMap = {
  [TipoTitularidadeCartaoEnum.TITULAR]: 'Titular',
  [TipoTitularidadeCartaoEnum.ADICIONAL]: 'Adicional',
};

export const statusProcessoAlteracaoDadosUsuarioClienteLabelMap = {
  [StatusProcessoAlteracaoDadosUsuarioClienteEnum.APROVADO]: 'Aprovado',
  [StatusProcessoAlteracaoDadosUsuarioClienteEnum.EM_PROCESSAMENTO]: 'Em processamento',
  [StatusProcessoAlteracaoDadosUsuarioClienteEnum.REPROVADO]: 'Reprovado',
};

export const statusProcessoAlteracaoDadosUsuarioClienteColorMap = {
  [StatusProcessoAlteracaoDadosUsuarioClienteEnum.APROVADO]: 'success',
  [StatusProcessoAlteracaoDadosUsuarioClienteEnum.REPROVADO]: 'danger',
  [StatusProcessoAlteracaoDadosUsuarioClienteEnum.EM_PROCESSAMENTO]: 'secondary',
};

export const statusProcessamentoLivenessProcessoAlteracaoDadosUsuarioClienteLabelMap = {
  [StatusProcessamentoLivenessProcessoAlteracaoDadosUsuarioClienteEnum.APROVADO]: 'Aprovado',
  [StatusProcessamentoLivenessProcessoAlteracaoDadosUsuarioClienteEnum.INCONCLUSIVO]: 'Inconclusivo',
  [StatusProcessamentoLivenessProcessoAlteracaoDadosUsuarioClienteEnum.REPROVADO]: 'Reprovado',
};

export const statusProcessamentoDocumentacaoProcessoAlteracaoDadosUsuarioClienteLabelMap = {
  [StatusProcessamentoDocumentacaoProcessoAlteracaoDadosUsuarioClienteEnum.APROVADO]: 'Aprovado',
  [StatusProcessamentoDocumentacaoProcessoAlteracaoDadosUsuarioClienteEnum.EM_PROCESSAMENTO]: 'Em processamento',
  [StatusProcessamentoDocumentacaoProcessoAlteracaoDadosUsuarioClienteEnum.REPROVADO]: 'Reprovado',
};

export const resultadoProcessamentoDocumentacaoClienteLabelMap = {
  [ResultadoProcessamentoDocumentacaoClienteEnum.APROVACAO_MANUAL]: 'Aprovação manual',
  [ResultadoProcessamentoDocumentacaoClienteEnum.CONVERGENTE]: 'Convergente',
  [ResultadoProcessamentoDocumentacaoClienteEnum.DIVERGENTE]: 'Divergente',
  [ResultadoProcessamentoDocumentacaoClienteEnum.REQUER_ANALISE]: 'Requer análise',
  [ResultadoProcessamentoDocumentacaoClienteEnum.REPROVACAO_MANUAL]: 'Reprovação manual',
  [ResultadoProcessamentoDocumentacaoClienteEnum.VALIDO]: 'Válido',
};

export const codigoConfirmacaoClienteLabelMap = {
  [TipoCodigoConfirmacaoClienteEnum.ABERTURA_CONTA]: 'Abertura de conta',
  [TipoCodigoConfirmacaoClienteEnum.RECUPERACAO_SENHA]: 'Recuperação de senha',
  [TipoCodigoConfirmacaoClienteEnum.TROCA_DISPOSITIVO]: 'Troca de dispositivo',
};

export const statusProcessamentoDocumentacaoSolicitacaoAberturaContaLabelMap = {
  [StatusProcessamentoDocumentacaoSolicitacaoAberturaContaEnum.DADOS_OBTIDOS]: 'Dados obtidos',
  [StatusProcessamentoDocumentacaoSolicitacaoAberturaContaEnum.EM_PROCESSAMENTO_EXTERNO]: 'Em processamento externo',
  [StatusProcessamentoDocumentacaoSolicitacaoAberturaContaEnum.FINALIZADO]: 'Finalizado',
  [StatusProcessamentoDocumentacaoSolicitacaoAberturaContaEnum.PROCESSAMENTO_EXTERNO_CONCLUIDO]:
    'Processamento externo concluído',
  [StatusProcessamentoDocumentacaoSolicitacaoAberturaContaEnum.PROCESSAMENTO_NAO_INICIADO]:
    'Processamento não iniciado',
};

export const statusSolicitacaoAberturaContaLabelMap = {
  [StatusSolicitacaoAberturaContaEnum.APROVADO]: 'Aprovado',
  [StatusSolicitacaoAberturaContaEnum.EM_PROCESSAMENTO]: 'Em processamento',
  [StatusSolicitacaoAberturaContaEnum.PENDENTE]: 'Pendente',
  [StatusSolicitacaoAberturaContaEnum.REPROVADO]: 'Reprovado',
  [StatusSolicitacaoAberturaContaEnum.REQUER_ANALISE]: 'Requer análise',
};

export const statusSolicitacaoAberturaContaColorMap = {
  [StatusSolicitacaoAberturaContaEnum.APROVADO]: ClassTypesEnum.SUCCESS,
  [StatusSolicitacaoAberturaContaEnum.EM_PROCESSAMENTO]: ClassTypesEnum.SECONDARY,
  [StatusSolicitacaoAberturaContaEnum.PENDENTE]: ClassTypesEnum.WARNING,
  [StatusSolicitacaoAberturaContaEnum.REPROVADO]: ClassTypesEnum.DANGER,
  [StatusSolicitacaoAberturaContaEnum.REQUER_ANALISE]: ClassTypesEnum.WARNING,
};

export const tipoDocumentoLabelMap = {
  [TipoDocumentoClienteEnum.CNH]: 'CNH',
  [TipoDocumentoClienteEnum.RG]: 'RG',
};

export const rotinasContaCartaoLabelMap = {
  [RotinaContaCartaoEnum.BLOQUEAR_CARTOES_VENCIDOS]: 'Bloquear cartões vencidos',
  [RotinaContaCartaoEnum.CALCULAR_DESCONTO_EM_FOLHA_CONSIGNADO]: 'Calcular desconto em folha consignado',
  [RotinaContaCartaoEnum.CANCELAR_TRANSACOES_PENDENTES]: 'Cancelar transações pendentes',
  [RotinaContaCartaoEnum.CLASSIFICAR_STATUS_CONTA_CARTAO]: 'Classificar status conta cartão',
  [RotinaContaCartaoEnum.COBRAR_TARIFAS_PERIODICAS]: 'Cobrar tarifas periódicas',
  [RotinaContaCartaoEnum.COBRAR_TARIFA_NOVA_VIA_CARTAO]: 'Cobrar tarifa nova via cartão',
  [RotinaContaCartaoEnum.COBRAR_TARIFA_PRIMEIRO_USO_CARTAO]: 'Cobrar tarifa primeiro uso',
  [RotinaContaCartaoEnum.COBRAR_TARIFA_SAQUE]: 'Cobrar tarifa saque',
  [RotinaContaCartaoEnum.CONTROLAR_ATRASO_CONTA_CARTAO]: 'Controlar atraso conta cartão',
  [RotinaContaCartaoEnum.CONTROLAR_ROTATIVO_CONTA_CARTAO]: 'Controlar rotativo conta cartão',
  [RotinaContaCartaoEnum.DESBLOQUEAR_CARTOES_BLOQUEADOS_CVC_INCORRETO]: 'Desbloquear cartões bloqueados CVC incorreto',
  [RotinaContaCartaoEnum.DESBLOQUEAR_CARTOES_BLOQUEADOS_SENHA_INCORRETA]:
    'Desbloquear cartões bloqueados senha incorreta',
  [RotinaContaCartaoEnum.FATURAR_PARCELAS]: 'Faturar parcelas',
  [RotinaContaCartaoEnum.FATURAR_TRANSACOES_PERIODO_ABERTO]: 'Faturar transações periódico aberto',
  [RotinaContaCartaoEnum.FECHAR_FATURA]: 'Fechar fatura',
  [RotinaContaCartaoEnum.GERAR_BOLETO]: 'Gerar boleto',
  [RotinaContaCartaoEnum.PARCELAR_FATURA_AUTOMATICAMENTE]: 'Parcelar fatura automaticamente',
  [RotinaContaCartaoEnum.PROCESSAR_DOACAO_RECORRENTE_MENSAL]: 'Processar doação recorrente mensal',
  [RotinaContaCartaoEnum.PROCESSAR_SOLICITACAO_PARCELAMENTO_FATURA]: 'Processar solicitação parcelamento fatura',
  [RotinaContaCartaoEnum.REGISTRAR_PAGAMENTO_DESCONTO_FOLHA_AUTOMATICO]:
    'Registrar pagamento desconto folha automático',
  [RotinaContaCartaoEnum.ZERAR_ERROS_SENHA_CVC]: 'Zerar erros senha CVC',
};

export const tipoCreditoLabelMap = {
  [TipoCreditoEnum.CONSIGNADO]: 'Consignado',
  [TipoCreditoEnum.MULTIBENEFICIO]: 'Multibenefício',
  [TipoCreditoEnum.PRE_PAGO]: 'Pré-pago',
  [TipoCreditoEnum.PURO]: 'Crédito puro',
};

export const tiposOperacaoBeneficioLabelMap = {
  [TipoBeneficioEnum.ALIMENTACAO]: 'Alimentação',
  [TipoBeneficioEnum.REFEICAO]: 'Refeição',
  [TipoBeneficioEnum.MOBILIDADE]: 'Mobilidade',
  [TipoBeneficioEnum.SAUDE]: 'Saúde',
  [TipoBeneficioEnum.CULTURA]: 'Cultura',
  [TipoBeneficioEnum.EDUCACAO]: 'Educação',
  [TipoBeneficioEnum.HOME_OFFICE]: 'Home Office',
};

export const tipoRelatorioOperacaoContextoLabelMap = {
  [ContextoProtocoloProcessamentoLoteEnum.RELATORIO_OPERACAO_AUTORIZACOES]: 'Autorizações',
};

export const tipoRelatorioOperacaoLabelMap = {
  [TipoRelatorioOperacaoEnum.AUTORIZACOES]: 'Autorizações',
};

type ContextoRotinasProps = {
  [key: string]: {
    nome: string;
    contexto: ContextoProtocoloProcessamentoLoteEnum;
    app?: string;
  };
};

export const contextosRotinasFrequentesMap: ContextoRotinasProps = {
  processarSolicitacoesAberturaConta: {
    nome: 'Processar Solicitações de Abertura de Conta',
    contexto: ContextoProtocoloProcessamentoLoteEnum.PROCESSAR_SOLICITACOES_DE_ABERTURA_DE_CONTA,
  },
  registrarPagamentoBoletosBancarios: {
    nome: 'Registrar Pagamento Boletos',
    contexto: ContextoProtocoloProcessamentoLoteEnum.REGISTRAR_PAGAMENTO_BOLETOS,
  },
};

export const contextosRotinasMap: ContextoRotinasProps = {
  cadastrarTaxaSelic: {
    nome: 'Cadastrar Taxa Selic',
    contexto: ContextoProtocoloProcessamentoLoteEnum.CADASTRAR_TAXA_SELIC,
  },
  cadastrarTaxasCambio: {
    nome: 'Cadastrar Taxas de Câmbio',
    contexto: ContextoProtocoloProcessamentoLoteEnum.CADASTRAR_TAXAS_CAMBIO,
  },
  disponibilizarAceiteContratoTermosUsoCliente: {
    nome: 'Disponibilizar Aceite de Contrato Termos de Uso para Clientes',
    contexto: ContextoProtocoloProcessamentoLoteEnum.DISPONIBILIZAR_ACEITE_CONTRATO_TERMOS_USO_CLIENTE,
  },
  enviarPushNotificationsBloqueioCartao: {
    nome: 'Enviar Push Notifications Bloqueio Cartão',
    contexto: ContextoProtocoloProcessamentoLoteEnum.ENVIAR_PUSH_NOTIFICATION_BLOQUEIO_CARTAO,
  },
  enviarPushNotificationsBloqueioContaCartao: {
    nome: 'Enviar Push Notifications Bloqueio Conta Cartão',
    contexto: ContextoProtocoloProcessamentoLoteEnum.ENVIAR_PUSH_NOTIFICATION_BLOQUEIO_CONTA_CARTAO,
  },
  enviarPushNotificationsDoacaoRecorrente: {
    nome: 'Enviar Push Notifications Lembrete Doação Recorrente Mensal',
    contexto: ContextoProtocoloProcessamentoLoteEnum.ENVIAR_PUSH_NOTIFICATION_LEMBRETE_DOACAO_RECORRENTE_MENSAL,
  },
  enviarPushNotificationsFechamentoFatura: {
    nome: 'Enviar Push Notifications Fechamento Fatura',
    contexto: ContextoProtocoloProcessamentoLoteEnum.ENVIAR_PUSH_NOTIFICATION_FECHAMENTO_FATURA,
  },
  enviarPushNotificationsLembreteFatura: {
    nome: 'Enviar Push Notifications Lembrete Fatura',
    contexto: ContextoProtocoloProcessamentoLoteEnum.ENVIAR_PUSH_NOTIFICATION_LEMBRETE_FATURA,
  },
  gerarCalendariosCorte: {
    nome: 'Gerar Calendários de Corte',
    contexto: ContextoProtocoloProcessamentoLoteEnum.GERAR_CALENDARIOS_DE_CORTE,
  },
  gerarPdfFaturas: {
    nome: 'Gerar PDF Faturas',
    contexto: ContextoProtocoloProcessamentoLoteEnum.GERAR_PDF_FATURAS,
  },
  registrarBoletosBancarios: {
    nome: 'Registrar Boletos',
    contexto: ContextoProtocoloProcessamentoLoteEnum.REGISTRAR_BOLETOS,
  },
  registrarSaldoDiaContaContabil: {
    nome: 'Registrar Saldo Dia Conta Contábil',
    contexto: ContextoProtocoloProcessamentoLoteEnum.REGISTRAR_SALDO_DIA_CONTA_CONTABIL,
    app: 'banking-accounting',
  },
};

export type GerarArquivoMapProps = {
  [key: string]: {
    nome: string;
    contexto: string;
    periodicidade?: string;
  };
};

export const geracaoArquivoMap: GerarArquivoMapProps = {
  atualizacaoSaldo: {
    nome: 'Arquivo de Atualização de Saldo',
    contexto: ContextoProtocoloProcessamentoLoteEnum.ARQUIVO_ATUALIZACAO_DE_SALDO,
    periodicidade: 'diario',
  },
  previas: {
    nome: 'Arquivo de Prévia',
    contexto: ContextoProtocoloProcessamentoLoteEnum.ARQUIVO_PREVIAS,
    periodicidade: 'diario',
  },
  arquivo5186: {
    nome: 'Arquivo Transações Moeda Estrangeira (5186)',
    contexto: ContextoProtocoloProcessamentoLoteEnum.ARQUIVO_5186,
    periodicidade: 'mensal',
  },
  cnab240RemessaSantander: {
    nome: 'Arquivo Cnab 240 Santander',
    contexto: ContextoProtocoloProcessamentoLoteEnum.ARQUIVO_CNAB_DE_REMESSA,
    periodicidade: 'diario',
  },
  cnab400RemessaSantander: {
    nome: 'Arquivo Cnab 400 Santander',
    contexto: ContextoProtocoloProcessamentoLoteEnum.ARQUIVO_CNAB_DE_REMESSA,
    periodicidade: 'diario',
  },
  cnab400RemessaBradesco: {
    nome: 'Arquivo Cnab Bradesco Remessa',
    contexto: ContextoProtocoloProcessamentoLoteEnum.ARQUIVO_CNAB_DE_REMESSA,
    periodicidade: 'diario',
  },
  decred: {
    nome: 'Arquivo Decred',
    contexto: ContextoProtocoloProcessamentoLoteEnum.ARQUIVO_DECRED,
    periodicidade: 'semestral',
  },
  cadoc3026: {
    nome: 'Arquivo Cadoc 3026',
    contexto: ContextoProtocoloProcessamentoLoteEnum.ARQUIVO_CADOC_3026,
    periodicidade: 'demanda',
  },
  cadoc3040: {
    nome: 'Arquivo Cadoc 3040',
    contexto: ContextoProtocoloProcessamentoLoteEnum.ARQUIVO_CADOC_3040,
    periodicidade: 'mensal',
  },
  cadoc3040Complementar: {
    nome: 'Arquivo Cadoc 3040 Complementar',
    contexto: ContextoProtocoloProcessamentoLoteEnum.ARQUIVO_CADOC_3040_COMPLEMENTAR,
    periodicidade: 'mensal',
  },
  cadoc3050: {
    nome: 'Arquivo Cadoc 3050',
    contexto: ContextoProtocoloProcessamentoLoteEnum.ARQUIVO_CADOC_3050,
    periodicidade: 'semanal',
  },
  cadoc6308: {
    nome: 'Arquivo Cadoc 6308',
    contexto: ContextoProtocoloProcessamentoLoteEnum.ARQUIVO_6308,
    periodicidade: 'trimestral',
  },
  cadoc6308Database: {
    nome: 'Arquivo 6308 Database',
    contexto: ContextoProtocoloProcessamentoLoteEnum.ARQUIVO_6308_DATABASE,
    periodicidade: 'trimestral',
  },
  cadoc6308Emissor: {
    nome: 'Arquivo 6308 Emissor',
    contexto: ContextoProtocoloProcessamentoLoteEnum.ARQUIVO_6308_EMISSOR,
    periodicidade: 'trimestral',
  },
  cadoc6308Portador: {
    nome: 'Arquivo 6308 Portador',
    contexto: ContextoProtocoloProcessamentoLoteEnum.ARQUIVO_6308_PORTADOR,
    periodicidade: 'trimestral',
  },
  contabil: {
    nome: 'Arquivo contabil',
    contexto: ContextoProtocoloProcessamentoLoteEnum.ARQUIVO_CONTABIL,
    periodicidade: 'diario',
  },
  abu: {
    nome: 'Arquivo ABU R274',
    contexto: ContextoProtocoloProcessamentoLoteEnum.ABU_R274,
  },
  embossing: {
    nome: 'Arquivo de Embossing',
    contexto: ContextoProtocoloProcessamentoLoteEnum.ARQUIVO_EMBOSSING,
  },
  analiticoDiario: {
    nome: 'Arquivo Contábil Analítico Diário',
    contexto: ContextoProtocoloProcessamentoLoteEnum.ARQUIVO_CONTABIL_ANALITICO_DIARIO,
    periodicidade: 'diario',
  },
  contabilSumarizado: {
    nome: 'Arquivo Contábil Sumarizado',
    contexto: ContextoProtocoloProcessamentoLoteEnum.ARQUIVO_CONTABIL_SUMARIZADO,
    periodicidade: 'demanda',
  },
  transacaoLojistaSumarizado: {
    nome: 'Arquivo Transação Lojista Sumarizado',
    contexto: ContextoProtocoloProcessamentoLoteEnum.ARQUIVO_TRANSACAO_LOJISTA_SUMARIZADO,
    periodicidade: 'demanda',
  },
  transacaoPortadorAnalitico: {
    nome: 'Arquivo Transação Portador Analítico',
    contexto: ContextoProtocoloProcessamentoLoteEnum.ARQUIVO_TRANSACAO_PORTADOR_ANALITICO,
    periodicidade: 'demanda',
  },
  iofAnalitico: {
    nome: 'Arquivo Iof Analitico',
    contexto: ContextoProtocoloProcessamentoLoteEnum.ARQUIVO_IOF_ANALITICO,
    periodicidade: 'demanda',
  },
  iofEstorno: {
    nome: 'Arquivo Iof Estorno',
    contexto: ContextoProtocoloProcessamentoLoteEnum.ARQUIVO_IOF_ESTORNO,
    periodicidade: 'demanda',
  },
  iofSinteticoDiario: {
    nome: 'Arquivo Iof Sintetico Diário',
    contexto: ContextoProtocoloProcessamentoLoteEnum.ARQUIVO_IOF_SINTETICO_DIARIO,
    periodicidade: 'demanda',
  },
};

export type ImportarArquivoMapProps = {
  [key: string]: {
    nome: string;
    contexto: ContextoProtocoloProcessamentoLoteEnum;
  };
};

export const importacaoArquivoMap: ImportarArquivoMapProps = {
  cadastral: {
    nome: 'Aqruivo Cadastral',
    contexto: ContextoProtocoloProcessamentoLoteEnum.ARQUIVO_CADASTRAL,
  },
  caf501: {
    nome: 'Arquivo CAF 501',
    contexto: ContextoProtocoloProcessamentoLoteEnum.ARQUIVO_CAF_501,
  },
  calendarioCorte: {
    nome: 'Arquivo de Calendário de Corte',
    contexto: ContextoProtocoloProcessamentoLoteEnum.CALENDARIO_DE_CORTE,
  },
  faixasMcc: {
    nome: 'Arquivo de faixas de MCC',
    contexto: ContextoProtocoloProcessamentoLoteEnum.ARQUIVO_FAIXAS_MCC,
  },
  bradescoCnab400: {
    nome: 'Bradesco - Arquivo Cnab 400',
    contexto: ContextoProtocoloProcessamentoLoteEnum.ARQUIVO_CNAB_DE_RETORNO,
  },
  goodcardApresentacao: {
    nome: 'Goodcard - Arquivo de apresentação',
    contexto: ContextoProtocoloProcessamentoLoteEnum.ARQUIVO_APRESENTACAO_GOODCARD,
  },
  mastercardChargeback: {
    nome: 'Mastercard - Arquivo de Chargeback',
    contexto: ContextoProtocoloProcessamentoLoteEnum.ARQUIVO_CHARGEBACK,
  },
  mastercardClearing: {
    nome: 'Mastercard - Arquivo de Clearing',
    contexto: ContextoProtocoloProcessamentoLoteEnum.ARQUIVO_CLEARING,
  },
  santanderCnab240: {
    nome: 'Santander - Arquivo Cnab 240',
    contexto: ContextoProtocoloProcessamentoLoteEnum.ARQUIVO_CNAB_DE_RETORNO,
  },
  santanderCnab400: {
    nome: 'Santander - Arquivo Cnab 400',
    contexto: ContextoProtocoloProcessamentoLoteEnum.ARQUIVO_CNAB_DE_RETORNO,
  },
};

export const designCartaoLabelMap = {
  [DesignCartaoEnum.TAMBORINE_V1]: 'Tamborine Versão 1',
  [DesignCartaoEnum.BALEIA_V1]: 'Baleia Versão 1',
};

export const bandeiraLabelMap = {
  [BandeiraEnum.AMERICAN_EXPRESS]: 'American Express',
  [BandeiraEnum.GOODCARD]: 'Goodcard',
  [BandeiraEnum.MASTERCARD]: 'Mastercard',
};

export const produtoBandeiraLabelMap = {
  [ProdutoBandeiraEnum.GOODCARD_STANDARD]: 'GOODCARD_STANDARD',
  [ProdutoBandeiraEnum.MASTERCARD_STANDARD]: 'MASTERCARD_STANDARD',
  [ProdutoBandeiraEnum.MASTERCARD_PRE_PAGO]: 'MASTERCARD_PRE_PAGO',
};
