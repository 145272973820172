import BSContainer from 'react-bootstrap/Container';
import { BsTag, BsPatchCheck, BsCurrencyExchange, BsFileEarmarkRuled } from 'react-icons/bs';
import AtalhoCard, { AtalhoCardProps } from './atalho-card';

const AtalhosSection = () => {
  const atalhos: AtalhoCardProps[] = [
    {
      label: 'Solicitações de clientes',
      navigateTo: '/solicitacoes-clientes',
      Icon: BsTag,
    },
    {
      label: 'Solicitações de abertura de conta',
      navigateTo: '/solicitacoes-abertura-conta',
      Icon: BsPatchCheck,
    },
    {
      label: 'Taxas de câmbio',
      navigateTo: '/taxas-cambio',
      Icon: BsCurrencyExchange,
    },
    {
      label: 'Tipos de solicitação de cliente',
      navigateTo: '/tipos-solicitacao-cliente',
      Icon: BsFileEarmarkRuled,
    },
  ];

  return (
    <BSContainer className="mb-5">
      <div className="mb-4">
        <h4 className="mb-0">Acesso rápido</h4>
      </div>

      <div className="row">
        {atalhos.map((atalho, index: number) => (
          <div className="col-lg-3 col-md-6 col-sm-12" key={index}>
            <div className="p-1 h-100">
              <AtalhoCard {...atalho} />
            </div>
          </div>
        ))}
      </div>
    </BSContainer>
  );
};

export default AtalhosSection;
