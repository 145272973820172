import { AcaoPermissaoPapelUsuarioEnum, ContextoProtocoloProcessamentoLoteEnum } from '@tamborineapps/lib-enums';
import { useEffect, useMemo, useState } from 'react';
import BSBadge from 'react-bootstrap/Badge';
import BSAccordion from 'react-bootstrap/Accordion';
import { useParams } from 'react-router-dom';
import DownloadFileButton from '../../components/button/download-file-button';
import { DetailCard } from '../../components/details/datail-card';
import DetailElement from '../../components/details/detail-element';
import DetailSubTitle from '../../components/details/detail-subtitle';
import DetailTitle from '../../components/details/detail-title';
import { Loading } from '../../components/loading';
import RbacElement from '../../components/role-based-access-control/role-based-access-control-element';
import RbacPage from '../../components/role-based-access-control/role-based-access-control-page';
import { FormatValueEnum, statusProcessamentoColorMap, tipoProtocoloProcessamentoLoteLabelMap } from '../../helpers';
import { useAppDispatch, useAppSelector } from '../../store/hooks-redux';
import {
  loadProtocoloProcessamentoLote,
  selectLoadingStateByFiltersProtocoloProcessamentoLote,
  selectProtocoloProcessamentoLoteById,
} from './protocolo-processamento-lote.redux';

const pathRelatorioOperacaoPorContextoMap = {
  [ContextoProtocoloProcessamentoLoteEnum.RELATORIO_OPERACAO_AUTORIZACOES]: 'autorizacoes',
};

const DetalhesProtocoloProcessamentoLotePage: React.FC = () => {
  const dispatch = useAppDispatch();
  const useSelector = useAppSelector;

  const params = useParams();
  const protocoloProcessamentoLoteId = params.protocoloProcessamentoLoteId as string;

  const [activeKeys, setDefaultActiveKeys] = useState<string[]>([]);
  const [allExpanded, setAllExpanded] = useState<boolean>(false);

  const filters = useMemo(() => ({ protocoloProcessamentoLoteId }), [protocoloProcessamentoLoteId]);
  const protocoloProcessamentoLote = useSelector((state) =>
    selectProtocoloProcessamentoLoteById(state, protocoloProcessamentoLoteId)
  );
  const loadingState = useSelector((state) => selectLoadingStateByFiltersProtocoloProcessamentoLote(state, filters));

  const nomeArquivo =
    protocoloProcessamentoLote?.parametros?.arquivoImportado ?? protocoloProcessamentoLote?.resultados?.arquivoGerado;
  const arquivoGerado = Boolean(protocoloProcessamentoLote?.resultados?.arquivoGerado);
  const erros = protocoloProcessamentoLote?.resultados?.erros;

  const toggleHandler = (eventKey: string) => {
    if (activeKeys.includes(eventKey)) {
      setDefaultActiveKeys(activeKeys.filter((key) => key !== eventKey));
    } else {
      setDefaultActiveKeys([...activeKeys, eventKey]);
    }
  };

  const toggleAllHandler = () => {
    if (allExpanded) {
      setAllExpanded(false);
      setDefaultActiveKeys([]);

      return;
    }

    const activeKeys = erros?.map((_: any, index: number) => index.toString());

    setAllExpanded(true);
    setDefaultActiveKeys(activeKeys);
  };

  useEffect(() => {
    dispatch(loadProtocoloProcessamentoLote({ protocoloProcessamentoLoteId })).catch((error: Error) => error);
  }, [dispatch, protocoloProcessamentoLoteId]);

  if (!protocoloProcessamentoLote) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <Loading notFoundMessage="Protocolo de processamento de lote não econtrado" loadingState={loadingState} />
      </div>
    );
  }

  return (
    <RbacPage acoesPermissao={AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_PROTOCOLO_PROCESSAMENTO_LOTE}>
      <DetailCard>
        <div className="row mb-5">
          <div className="col-md-8 col-sm-12 mb-3">
            <DetailTitle>
              Protocolo de processamento -{' '}
              {
                tipoProtocoloProcessamentoLoteLabelMap[
                  protocoloProcessamentoLote.tipo as keyof typeof tipoProtocoloProcessamentoLoteLabelMap
                ]
              }{' '}
              - {protocoloProcessamentoLote.contexto}
            </DetailTitle>
          </div>

          <div className="col-md-4 col-sm-12 text-md-end text-sm-start">
            <h4 className="mb-0">
              <BSBadge
                pill
                bg={
                  statusProcessamentoColorMap[
                    protocoloProcessamentoLote.status as keyof typeof statusProcessamentoColorMap
                  ]
                }
              >
                {protocoloProcessamentoLote.status}
              </BSBadge>
            </h4>
          </div>
        </div>

        <div className="row">
          {nomeArquivo && (
            <div className="col-lg-12 col-md-12">
              <DetailElement descricao="Arquivo" valor={nomeArquivo} />
              <DetailElement
                descricao="Parâmetros do armazenamento"
                valor={JSON.stringify(protocoloProcessamentoLote?.resultados?.argumentos)}
              />
            </div>
          )}
          <div className="col-lg-4 col-md-6">
            <RbacElement acoesPermissao={AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_RELATORIO_OPERACAO_AUTORIZACOES}>
              {protocoloProcessamentoLote?.resultados?.relatorioOperacaoId && (
                <DetailElement
                  descricao="Relatório"
                  valor="Ver relatório"
                  link={`/relatorios-operacao/${
                    pathRelatorioOperacaoPorContextoMap[
                      protocoloProcessamentoLote.contexto as keyof typeof pathRelatorioOperacaoPorContextoMap
                    ]
                  }/${protocoloProcessamentoLote?.resultados?.relatorioOperacaoId}`}
                />
              )}
            </RbacElement>

            <DetailElement
              descricao="Data de início"
              valor={protocoloProcessamentoLote.dataHoraInicio}
              format={FormatValueEnum.DATE_TIME}
            />
            <DetailElement
              descricao="Data de término"
              valor={protocoloProcessamentoLote.dataHoraTermino}
              format={FormatValueEnum.DATE_TIME}
            />
            <DetailElement
              descricao="Provedor do serviço de armazenamento"
              valor={protocoloProcessamentoLote?.resultados?.provedorArmazenamento}
            />
            <DetailElement
              descricao="Lote do arquivo processado"
              valor={protocoloProcessamentoLote?.resultados?.numeroLote}
            />
          </div>
        </div>

        {arquivoGerado && (
          <RbacElement acoesPermissao={AcaoPermissaoPapelUsuarioEnum.DOWNLOAD_ARQUIVO}>
            <div className="row">
              <div className="col-12 d-flex justify-content-end flex-wrap">
                <DownloadFileButton
                  url={`/api/protocolos-processamento-lote/${protocoloProcessamentoLoteId}/download-arquivo`}
                  fileName={nomeArquivo}
                >
                  Download do arquivo
                </DownloadFileButton>
              </div>
            </div>
          </RbacElement>
        )}

        {Boolean(erros?.length) && (
          <>
            <div className="row my-4">
              <div className="col-md-8 col-sm-12 mb-3">
                <DetailSubTitle>Erros ({erros.length})</DetailSubTitle>
              </div>

              <div className="col-md-4 col-sm-12 text-md-end text-sm-start">
                <span className="text-primary" role="button" onClick={toggleAllHandler}>
                  {allExpanded ? 'Fechar todos' : 'Expandir todos'}
                </span>
              </div>
            </div>

            <div className="col-12">
              <BSAccordion activeKey={activeKeys} alwaysOpen>
                {erros.map((erro: any, index: number) => (
                  <BSAccordion.Item eventKey={index.toString()} key={index}>
                    <BSAccordion.Header onClick={() => toggleHandler(index.toString())}>
                      Erro {index + 1}
                    </BSAccordion.Header>
                    <BSAccordion.Body>
                      <span>
                        <strong>Mensagem</strong>
                      </span>

                      <p>{erro?.mensagem}</p>

                      <span>
                        <strong>Stack</strong>
                      </span>

                      <ul>{erro?.stack?.split('\n').map((stack: string) => <li>{stack}</li>)}</ul>
                    </BSAccordion.Body>
                  </BSAccordion.Item>
                ))}
              </BSAccordion>
            </div>
          </>
        )}
      </DetailCard>
    </RbacPage>
  );
};

export default DetalhesProtocoloProcessamentoLotePage;
