import { AcaoPermissaoPapelUsuarioEnum } from '@tamborineapps/lib-enums';
import BSContainer from 'react-bootstrap/Container';
import RbacElement from '../../components/role-based-access-control/role-based-access-control-element';
import SearchBoxCard from './search-box-card';

const PesquisaPorClienteSection: React.FC = () => {
  return (
    <RbacElement acoesPermissao={AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_CONTA_CARTAO}>
      <BSContainer className="mb-5">
        <div className="mb-4">
          <h4 className="mb-0">Pesquisa por cliente</h4>
        </div>

        <div className="w-100">
          <SearchBoxCard />
        </div>
      </BSContainer>
    </RbacElement>
  );
};

export default PesquisaPorClienteSection;
