import BSContainer from 'react-bootstrap/Container';
import { logo } from '../../assets';
import classes from './apresentacao-section.module.scss';

const ApresentacaoSection: React.FC = () => {
  return (
    <BSContainer fluid className={`${classes.container} mb-5`}>
      <div className="d-flex justify-content-center flex-column h-100">
        <div className="d-flex justify-content-center align-items-center">
          <div className="me-3">
            <img src={logo} alt="logo" className={classes.logo} />
          </div>

          <div>
            <span className={`${classes['logo-text']} m-0`}>tamborine</span>
            <h3 className="m-0">Operation System</h3>
          </div>
        </div>

        <h1 className={`${classes.text} text-center my-5`}>
          Controle absoluto da operação,
          <br />
          simplificado para você.
        </h1>

        <div className="d-flex justify-content-center">
          <h5 className="text-center m-0">A solução integrada para gerenciar cada detalhe da operação.</h5>
        </div>
      </div>
    </BSContainer>
  );
};

export default ApresentacaoSection;
