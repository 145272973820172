import { useCallback } from 'react';
import BSButton from 'react-bootstrap/Button';
import BSModal from 'react-bootstrap/Modal';

import { useForm } from 'react-hook-form';
import { Form } from '../../components/form/form';
import { FormControl, MasksFormControlEnum } from '../../components/form/form-control';
import { FormSelect } from '../../components/form/form-select';
import { ClassTypesEnum, moedaMap } from '../../helpers';
import { useToasts } from '../../hooks/toast/use-toasts';
import { useAppDispatch } from '../../store/hooks-redux';
import { saveTaxaCambio } from './taxas-cambio.redux';

type CadastrarTaxaCambioFormFields = {
  data: string;
  moeda: string;
  cotacao: string;
};

type CadastrarTaxasCambioProps = {
  closeDialog: () => void;
  reload: () => void;
};

const CadastrarTaxasCambioDialog: React.FC<CadastrarTaxasCambioProps> = ({ closeDialog, reload }) => {
  const dispatch = useAppDispatch();
  const { showToast } = useToasts();

  const form = useForm<CadastrarTaxaCambioFormFields>();
  const { control } = form;

  const onSubmitHandler = useCallback(
    (data: CadastrarTaxaCambioFormFields) => {
      dispatch(saveTaxaCambio({ data }))
        .then(() => {
          closeDialog();
          showToast({ message: 'Taxa de câmbio cadastrada com sucesso', type: ClassTypesEnum.SUCCESS });
          reload();
        })
        .catch((error: Error) => {
          showToast({ message: error.message, type: ClassTypesEnum.DANGER });
        });
    },
    [closeDialog, dispatch, reload, showToast]
  );

  return (
    <>
      <BSModal.Header closeButton>
        <BSModal.Title>Nova taxa de câmbio</BSModal.Title>
      </BSModal.Header>

      <BSModal.Body>
        <Form id="form" form={form} onSubmit={onSubmitHandler}>
          <FormControl
            control={control}
            className="mb-3"
            label="Data da cotação"
            name="data"
            type="date"
            rules={{ required: true }}
          />

          <FormSelect
            control={control}
            className="mb-3"
            label="Moeda"
            name="moeda"
            placeholder="Selecione a moeda"
            options={Object.values(moedaMap)?.map((moeda) => ({
              key: moeda.value,
              value: moeda.value,
              label: moeda.label,
            }))}
            rules={{ required: true }}
          />
          <FormControl
            control={control}
            className="mb-3"
            label="Cotação"
            name="cotacao"
            rules={{ required: true }}
            mask={MasksFormControlEnum.COTACAO}
          />
        </Form>
      </BSModal.Body>

      <BSModal.Footer>
        <BSButton variant="light" onClick={closeDialog}>
          Fechar
        </BSButton>
        <BSButton variant="primary" type="submit" form="form">
          Cadastrar
        </BSButton>
      </BSModal.Footer>
    </>
  );
};

export default CadastrarTaxasCambioDialog;
