import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { ApiMultiElementResponse, mapearERemoverElementosNulosERepetidos } from '../../helpers';
import { useQuerystring } from '../../hooks/router/use-querystring';
import { useAppDispatch, useAppSelector } from '../../store/hooks-redux';
import {
  loadSolicitacoesClientes,
  selectSolicitacaoClienteLoadingStateByFilters,
  selectSolicitacoesClientesByFilters,
  selectTotalSolicitacoesClientesByFilters,
} from '../solicitacoes-cliente/solicitacao-cliente.redux';
import SolicitacoesClienteSmartTable from '../solicitacoes-cliente/solicitacoes-cliente-smart-table';
import { loadUsuarios, selectObjectTodosUsuarios } from '../usuarios/usuarios.redux';

type SolicitacoesClienteContaCartaoTabProps = {
  contaCartaoId: string;
};

const SolicitacoesClienteContaCartaoTab: React.FC<SolicitacoesClienteContaCartaoTabProps> = ({ contaCartaoId }) => {
  const dispatch = useAppDispatch();
  const useSelector = useAppSelector;
  const navigate = useNavigate();

  const { query, maxItemsQuery } = useQuerystring();

  if (!query.sort) {
    query.sort = '-dataAbertura';
  }

  const filter = useMemo(() => ({ ...query, contaCartaoId }), [contaCartaoId, query]);
  const solicitacoesCliente = useSelector((state) => selectSolicitacoesClientesByFilters(state, filter));
  const total = useSelector((state) => selectTotalSolicitacoesClientesByFilters(state, filter));
  const loadingState = useSelector((state) => selectSolicitacaoClienteLoadingStateByFilters(state, filter));
  const usuarios = useSelector((state) => selectObjectTodosUsuarios(state));

  const loadEntidadesComplementares = useCallback(
    (data: any[]) => {
      const usuarios = mapearERemoverElementosNulosERepetidos(data, 'usuarioSolicitacao');

      dispatch(loadUsuarios({ query: { _id: { in: usuarios }, ...maxItemsQuery } })).catch((error: Error) => error);
    },
    [dispatch, maxItemsQuery]
  );

  const loadItems = useCallback(() => {
    dispatch(loadSolicitacoesClientes({ query: filter }))
      .then(({ payload: { data } }: ApiMultiElementResponse) => loadEntidadesComplementares(data))
      .catch((error: Error) => error);
  }, [dispatch, filter, loadEntidadesComplementares]);

  return (
    <>
      <SolicitacoesClienteSmartTable
        items={solicitacoesCliente}
        loadingState={loadingState}
        loadItems={loadItems}
        navigateTo={(doc) => navigate(`solicitacoes-cliente/${doc._id}`)}
        size={total}
        usuarios={usuarios}
      />
    </>
  );
};

export default SolicitacoesClienteContaCartaoTab;
