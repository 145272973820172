import { useCallback, useEffect, useMemo, useState } from 'react';
import BSCard from 'react-bootstrap/Card';
import { Link, useParams } from 'react-router-dom';

import { AcaoPermissaoPapelUsuarioEnum } from '@tamborineapps/lib-enums';
import { DetailCard } from '../../components/details/datail-card';
import DetailElement from '../../components/details/detail-element';
import DetailSubTitle from '../../components/details/detail-subtitle';
import DetailTitle from '../../components/details/detail-title';
import { Loading } from '../../components/loading';
import RbacPage from '../../components/role-based-access-control/role-based-access-control-page';
import {
  ApiSingleElementResponse,
  FormatValueEnum,
  clienteRename,
  sexoLabelMap,
  tipoAlteracaoHistoricoMap,
  tipoEnderecoLabelMap,
  tipoHistoricoMap,
  tipoPessoaLabelMap,
} from '../../helpers';
import { useAppDispatch, useAppSelector } from '../../store/hooks-redux';
import { loadCliente, selectClienteById } from '../clientes/clientes.redux';
import { loadHistoricoNoDiaCliente } from './historico-cliente-dia.redux';
import {
  loadHistorico,
  selectHistoricoClienteById,
  selectHistoricoClienteLoadingStateByFilters,
} from './historico-cliente.redux';

const DetalhesHistoricoClientePage: React.FC = () => {
  const dispatch = useAppDispatch();
  const useSelector = useAppSelector;

  const params = useParams();
  const historicoId = params.historicoId as string;
  const tipoHistorico = params.tipoHistorico as string;
  const clienteId = params.clienteId as string;

  const [dadosAnteriores, setDadosAnteriores] = useState<any>();

  const filters = useMemo(() => ({ clienteId, historicoId, tipoHistorico }), [clienteId, historicoId, tipoHistorico]);
  const historicoCliente = useSelector((state) => selectHistoricoClienteById(state, historicoId));
  const loadingState = useSelector((state) => selectHistoricoClienteLoadingStateByFilters(state, filters));
  const cliente = useSelector((state) => selectClienteById(state, clienteId));

  const _loadEntidadesComplementares = useCallback(() => {
    dispatch(loadCliente({ clienteId })).catch((error: Error) => error);
    dispatch(loadHistoricoNoDiaCliente({ historicoId, tipoHistorico }))
      .then(({ payload: { data } }: ApiSingleElementResponse) => {
        const { _id, ...dadosAnteriores } = data;
        return setDadosAnteriores(dadosAnteriores);
      })
      .catch((error: Error) => error);
  }, [dispatch, clienteId, historicoId, tipoHistorico]);

  const _loadHistorico = useCallback(
    () => dispatch(loadHistorico({ historicoId, tipoHistorico })).catch((error: Error) => error),
    [dispatch, historicoId, tipoHistorico]
  );

  useEffect(() => {
    _loadHistorico();
    _loadEntidadesComplementares();
  }, [_loadEntidadesComplementares, _loadHistorico]);

  function filterSameKeys(entidade: any, alteracao: any) {
    const keysAlteracao = Object.keys(alteracao);

    return Object.entries(entidade).reduce((sameKeys, entry) => {
      const [chave, valor] = entry;

      if (keysAlteracao.includes(chave)) {
        return { ...sameKeys, [chave]: valor };
      }

      return sameKeys;
    }, {});
  }

  const renderDetailElement = (atributo: string, valor: any): JSX.Element | undefined => {
    const nomeAtributo = clienteRename[atributo as keyof typeof clienteRename];
    if (!nomeAtributo) {
      return undefined;
    }

    function isElement() {
      return <DetailElement key={atributo} descricao={nomeAtributo} valor={valor} />;
    }

    function isCpfCnpj() {
      return <DetailElement key={atributo} descricao={nomeAtributo} valor={valor} format={FormatValueEnum.CPF_CNPJ} />;
    }

    function isDataNascimento() {
      return <DetailElement key={atributo} descricao={nomeAtributo} valor={valor} format={FormatValueEnum.DATA} />;
    }

    function isEhDeficienteVisual() {
      return <DetailElement key={atributo} descricao={nomeAtributo} valor={valor} format={FormatValueEnum.BOOL} />;
    }

    function isNomeSocial() {
      return <DetailElement key={atributo} descricao={nomeAtributo} valor={valor} format={FormatValueEnum.BOOL} />;
    }

    function isSexo() {
      return (
        <DetailElement
          key={atributo}
          descricao={nomeAtributo}
          valor={valor}
          format={FormatValueEnum.ENUM}
          map={sexoLabelMap}
        />
      );
    }

    function isTelefone() {
      return <DetailElement key={atributo} descricao={nomeAtributo} valor={valor} format={FormatValueEnum.TELEFONE} />;
    }

    function isTipo() {
      return (
        <DetailElement
          key={atributo}
          descricao={nomeAtributo}
          valor={valor}
          format={FormatValueEnum.ENUM}
          map={{
            ...tipoPessoaLabelMap,
            ...tipoEnderecoLabelMap,
          }}
        />
      );
    }

    function isDispositivoMovel() {
      if (valor.length === 0 || !valor) {
        return;
      }

      return (
        <>
          <DetailElement
            descricao="Identificador do dispositivo móvel de acesso ao aplicativo"
            valor={valor?.dispositivoId}
          />
          <DetailElement descricao="Token de aceso ao Firebase Cloud Messaging" valor={valor?.tokenFCM} />
          <DetailElement
            descricao="Endpoint utilizado para envio de push notifications pelo SNS"
            valor={valor?.endpointNotificacao}
          />
        </>
      );
    }

    const cases = {
      cpfCnpj: isCpfCnpj,
      dataNascimento: isDataNascimento,
      ehDeficienteVisual: isEhDeficienteVisual,
      nomeSocial: isNomeSocial,
      sexo: isSexo,
      tipo: isTipo,
      telefone: isTelefone,
      dispositivoMovel: isDispositivoMovel,
    };

    return Reflect.has(cases, atributo) ? cases[atributo as keyof typeof cases]() : isElement();
  };

  if (!historicoCliente) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <Loading notFoundMessage="Registro de histórico não econtrado" loadingState={loadingState} />
      </div>
    );
  }

  return (
    <RbacPage acoesPermissao={AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_HISTORICO_CLIENTE}>
      <div className="mb-5">
        <DetailCard>
          <div className="row mb-5">
            <div className="col-md-8 col-sm-12">
              <DetailTitle>
                Registro de histórico de alteração
                {Boolean(cliente) && (
                  <>
                    {' '}
                    - <Link to={`/clientes/${cliente._id}`}>{cliente.nome}</Link>
                  </>
                )}
              </DetailTitle>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-lg-4 col-md-6 mb-4">
              <DetailElement
                descricao="Entidade"
                valor={historicoCliente.tipo}
                format={FormatValueEnum.ENUM}
                map={tipoHistoricoMap}
              />
              <DetailElement
                descricao="Tipo da alteração"
                valor={historicoCliente.tipoAlteracao}
                format={FormatValueEnum.ENUM}
                map={tipoAlteracaoHistoricoMap}
              />
              <DetailElement
                descricao="Data da alteração"
                valor={historicoCliente.dataAlteracao}
                format={FormatValueEnum.DATE_TIME}
              />
              <DetailElement descricao="Origem da alteração" valor={historicoCliente.origemAlteracao} />
            </div>
          </div>

          <div className="row">
            <div className="col-md-12 col-lg-6 mb-4 d-flex flex-column">
              <div className="mb-4">
                <DetailSubTitle>Valores alterados</DetailSubTitle>
              </div>

              <BSCard className="flex-grow-1">
                <BSCard.Body className="p-5">
                  {historicoCliente.alteracao && (
                    <>
                      {Object.entries(historicoCliente.alteracao).map(([chave, valor]) => {
                        return renderDetailElement(chave, valor);
                      })}
                    </>
                  )}
                </BSCard.Body>
              </BSCard>
            </div>

            <div className="col-md-12 col-lg-6 mb-4 d-flex flex-column">
              <div className="mb-4">
                <DetailSubTitle>Valores anteriores</DetailSubTitle>
              </div>

              <BSCard className="flex-grow-1">
                <BSCard.Body className="p-5">
                  {dadosAnteriores && Object.keys(dadosAnteriores).length !== 0 ? (
                    <>
                      {Object.entries(filterSameKeys(dadosAnteriores, historicoCliente.alteracao)).map(
                        ([chave, valor]) => {
                          return renderDetailElement(chave, valor);
                        }
                      )}
                    </>
                  ) : (
                    <p>Sem alterações anteriores</p>
                  )}
                </BSCard.Body>
              </BSCard>
            </div>
          </div>
        </DetailCard>
      </div>
    </RbacPage>
  );
};

export default DetalhesHistoricoClientePage;
